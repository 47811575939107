@import "./base/mixin";

@include desktop {
  .tabs-content-init {
      .search-box {
          padding: 20px 40px;
          flex-direction: column;
          align-items: flex-start;

          .search-factor {
              .search-tit {
                  width: 100px;
              }

              & + .search-factor {
                  margin-top: 20px;
                  margin-left: 0;
              }
          }
      }
  }

  .result-summary-page {
    .result1 {
      .explain {
        flex-direction: column;

        .exp-tabs {
          width: 100% !important;
          grid-template-rows: 1fr !important;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-column-gap: 10px;
        }

        .exp-wrap {
          width: 100%;
          margin-top: 10px;
          min-width: unset;

          .diet-img {
            img {
              max-height: 190px;
            }
          }

          .diet-info {
            .info-score {
              flex-direction: column;

              .nut-1, .nut-2 {
                width: 80%;
                margin: 0;
                padding: 0;
              }

              .line {
                height: 16px;
                background: none;
              }
            }
          }

          .diet-result {
            justify-content: center;
          }
        }
      }

      .food-est-list {
        .exp-wrap {
          .diet-info {
            .info-score {
              flex-direction: column;
  
              .nut-1, .nut-2 {
                width: 100%;
                margin: 0;
                padding: 0;
              }
  
              .line {
                height: 16px;
                background: none;
              }
            }
          }

          .diet-result {
            justify-content: center;
          }
        }
      }
    }
  }
}

//metronic 모바일(max-width: 992px)
@include metronic {
    #kt_content {
        .flex-column-fluid {
            height: auto;
            overflow: hidden;
            padding-top: 54px;
        }
    }

    .topbar {
        display: none !important;
    }

    #kt_aside_menu {
        .menu-content {
            height: calc(100vh - 90px) !important;
        }
    }

    #kt_header_mobile {
        position: fixed;
        width: 100%;
        top: 0;

        .menu-link {
            &:hover {
            background: rgba(0, 0, 0, 0.3) !important;
            }

            .menu-text {
            color: #fff !important;
            font-size: 1.2rem;
            }
        }
    }

    .subheader {
        top: 55px !important;
    }

    .modal-dialog {
        max-width: 800px !important;
    }
}

//태블릿 가로
@include tablet {
  .subheader {
    top: 55px !important;
  }

  .tabs-content-init {
    .search-box {
      padding: 20px 40px;
      flex-direction: column;
      align-items: flex-start;
  
      .search-factor {
        .search-tit {
          width: 100px;
        }
  
        & + .search-factor {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }

  .result-summary-page {
    .result1 {

      .result-section {
        &.main {
          .explain {
            flex-direction: column;
    
            .exp-tabs {
              width: 100% !important;
              grid-template-rows: 1fr !important;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              grid-column-gap: 10px;
            }
    
            .exp-wrap {
              width: 100%;
              margin-top: 10px;
              min-width: unset;
    
              .diet-img {
                img {
                  max-height: 190px;
                }
              }
    
              .diet-info {
                .info-score {
                  flex-direction: column;
    
                  .nut-1, .nut-2 {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                  }
    
                  .line {
                    height: 16px;
                    background: none;
                  }
                }
              }
    
              .diet-result {
                justify-content: center;
              }
            }
          }
        }
      }

      .food-est-list {
        .exp-wrap {
          .diet-info {
            .info-score {
              flex-direction: column;
  
              .nut-1, .nut-2 {
                width: 90%;
                margin: 0;
                padding: 0;
              }
  
              .line {
                height: 16px;
                background: none;
              }
            }
          }

          .diet-result {
            justify-content: center;
          }
        }
      }
    }

    .result2 {

    }
  }

  .survey {
    .table-container {
      .section-answer {
        .selection {
          &.etc {
            .textbox {
              width: 60% !important;
            }
          }
        }
      }
    }
  }
}
  
  //모바일 가로-태블릿 세로
  @include mobile-lg {
    .subheader {
      top: 55px !important;
    }

    .modal-dialog {
      max-width: 500px !important;
  
      .food-input-wrap {
        padding: 20px;
  
        #beforeTime,
        #afterTime {
          width: 50% !important;
        }
      }
  
      .img-input-wrap {
        img {
          width: 72px !important;
          height: 72px !important;
        }
  
        .img-input-label {
          &.new {
            width: 72px !important;
            height: 72px !important;
          }
        }
      }
    }
  
    .tabs-content-init {
      .search-box {
        padding: 20px 30px;
        flex-direction: column;
        align-items: flex-start;
  
        .search-factor {
          .search-tit {
            width: 80px;
          }
  
          .dx-selectbox {
            width: 100px;
          }
  
          form {
            input {
              width: 140px;
            }
          }
  
          & + .search-factor {
            margin-top: 20px;
            margin-left: 0;
          }
  
          .btn-search {
            width: 100px;
          }
        }
      }
    }
  
    .page-wrap {
      .page-tit {
        flex-direction: column;
  
        .data-indate {
          font-size: 14px;
          margin-left: auto;
        }
      }
  
      .page-content-wrap {
        &.predict-result,
        &.ogtt-result,
        &.pattern-result,
        &.result-summary-page {
          background: none;
          box-shadow: none;
  
          .result {
            padding: 0;
  
            .section-tit {
              &.pc {
                display: none;
              }
  
              &.mobile {
                display: block;
                width: 100%;
                margin: 0 auto 20px;
              }
            }
  
            .section-init {
              flex-direction: column;
  
              .result-section {
                width: 100%;
                box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.15);
  
                &.main {
                  background: #fff;
                  border-radius: 20px;
  
                  .result-bar {
                      width: 100%;
                    padding: 40px;
                  }
                }
  
                &.explain {
                    border-radius: 20px;

                  .result-txt {
                    border: none;
                    padding: 0;
  
                    .result-exp {
                      width: 100%;
                      padding: 30px 20px;
                      margin: 0;
                      background: #fff;
                      border-radius: 10px;
                      border: none;
  
                      p {
                        width: 90%;
                        margin: 0 auto 12px;
                      }
                    }
  
                    .inner-foot {
                      padding: 20px;
                      margin: 15px auto 15px;
                      color: #fff;
                      background: #003668;
                      border-radius: 10px;
  
                      p {
                        width: 90%;
                        margin: 0 auto;
                      }
                    }
                  }
                }
  
                & + .result-section {
                  margin-top: 15px;
                }
              }
            }
          }
        }


        &.predict-result {
            .result {
                .result-section {
                    &.explain {
                        margin-left: 0;
                        background: #fff;
                        padding: 40px;
                    }
                }

                &.result1 {
                    .result-section {
                        &.explain {
                            .section-tit {
                                &.mobile {
                                    width: 100%;
                                }
                            }

                            .exp-wrap {
                                &> p {
                                    margin: 0 10px 10px;
                                }
                            }
    
                            .inner-foot {
                                margin: 30px auto 0;
                            }
                        }
                    }
                }

                &.result2 {
                    margin-top: 15px;
    
                    .result-section {
                        &.main {
                            .result-bar {
                                .bar-list {
                                    overflow: hidden;
                                    height: auto;
                                    min-height: 240px;
                                }
                            }
                        }
    
                        &.explain {
                            padding: 40px;
                        }
                    }
                }

                &.result3 {
                    background: #fff;
                    border-radius: 20px;
                    margin-top: 15px;
                    padding: 40px;
                    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.15);

                    .result-section {
                        box-shadow: none;
                        .result-txt {
                            margin: 0 0 30px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .exp-txt {
                                p {
                                    margin: 0 10px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
  
        &.pattern-result {
          .result1 {
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .section-tit {
                    width: 100%;
                    margin: 0 auto 20px;
                  }
  
                  .graph-wrap {
                    img {
                      width: 100%;
                    }
  
                    .polar-chart-tit {
                      margin-bottom: 0;
                    }
                  }

                  .result-section.explain {
                    box-shadow: none;
                    padding: 0;
                  }
                }
  
                &.explain {
                  border: none;
                  background: #fff;
  
                  .exp-wrap {
                    .exp-top {
                      flex-direction: column;
                      margin-bottom: 40px;
  
                      .exp-charts {
                        float: unset;
                        width: 100%;
                      }
  
                      .exp-right {
                        float: unset;
                        width: 100%;
                        padding: 0;
                        margin-top: 20px;
  
                        .exp-txt {
                            padding: 0 10px;
                          line-height: 24px;
  
                          & + .exp-txt {
                            margin-top: 20px;
                          }
                        }
                      }
                    }
                  }
  
                  .exp-score {
                    background: #f9faff;
  
                    .score-set {
                      flex-direction: column;
                      justify-content: unset;
  
                      .txt-a {
                        margin-right: auto;
                      }
  
                      .txt-r {
                        margin-left: auto;
                      }
                    }
                  }
  
                  .factor {
                    display: flex;
                    flex-direction: column;
  
                    .dish-img {
                      width: 100%;
                      padding-right: 0;
                    }
  
                    .factor-exp {
                        width: calc(100%);
                      padding: 0 10px;
                      margin-top: 30px;
                    }
                  }
                }
              }
            }
          }
  
          .result2 {
            margin-top: 15px;
  
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .graph-wrap {
                    width: 90%;
                    padding: 0;
  
                    h3 {
                      font-size: 16px;
                      margin: 0 0 20px 0;
                    }
                  }
                }
              }
  
              .slide-btn-wrap {
                width: 100%;
                margin-top: 15px;
                border-bottom: none;
  
                .slide-btn {
                  width: 100%;
                  height: 54px;
                  padding-left: 20px;
                  color: #fff;
                  text-align: left;
                  background: #039be5;
                  border-radius: 9px;
  
                  &.active {
                    background: #165b9b;
                  }
  
                  g {
                    rect {
                      fill: #fff;
                    }
                  }
                }
              }
  
              .exp-table-wrap {
                margin-top: 15px;
  
                &.active {
                  width: 100%;
                  background: #fff;
                  padding: 40px;
                  border-radius: 9px;
                  margin-bottom: 15px;
                }
              }
            }
          }
  
          .result3 {
            width: 100%;
            margin-top: 15px;
            padding: 0 !important;

            .section-tit {
              &.pc {
                display: none;
              }

              &.mobile {
                display: block;
                margin-bottom: 20px;
              }
            }
  
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .graph-wrap {
                    h3 {
                      margin: 0;
                      font-size: 18px;
                    }
                  }
                }
  
                &.explain {
                  padding: 40px;
                  background: #fff;
  
                  .result-exp {
                    .exp-outer {
                      width: 100%;
  
                      .exp-name {
                        font-size: 18px;
                      }
  
                      .exp-wrap {
                        background: #f9faff;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }

          .result4 {
            margin-top: 15px;

            .section-tit {
              &.pc {
                display: none;
              }

              &.mobile {
                display: block;
              }
            }

            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .graph-wrap {
                    h3 {
                      margin: 0;
                      font-size: 18px;
                    }
                  }
                }
  
                &.explain {
                  padding: 40px;
                  background: #fff;
                  border-radius: 20px;
  
                  .result-exp {
                    .exp-outer {
                      width: 100%;
  
                      .exp-name {
                        font-size: 18px;
                      }
  
                      .exp-wrap {
                        background: #f9faff;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
  
          .inner-foot {
            width: 100%;
            padding: 20px;
            margin: 15px auto 15px;
            color: #fff;
            background: #003668;
            border-radius: 10px;
  
            p {
              width: 90%;
              margin: 0 auto;
            }
          }
        }
  
        &.ogtt-result {
          .result {
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .section-tit {
                    width: 100%;
                    margin: 0 auto 20px;
                  }
  
                  .chart-wrap {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
  
                    .google-chart {
                      width: 100%;
                    }
                  }

                  table {
                      width: 100%;
                  }
                }
  
                &.explain {
                padding: 40px;
                  background: #fff;
                  border: none;
                }
              }
            }
          }

          .result1 {
              .exp-txt {
                  padding: 0 10px;
              }

              .result-section {
                  &.explain {
                      margin: 15px 0 0;
                  }
              }
          }
  
          .result2 {
            margin-top: 15px;
  
            .section-init {
              .result-section {
                box-shadow: 0px 2px 10px 0px rgba(0,0,0,.15);

                  &.main {
                    .section-tit {
                        margin-left: 0;
                        width: 100%;
                      }
                  }
                &.explain {
                  width: 100%;
                  padding: 0;
                  margin: 0 auto;
                  box-shadow: none;

                  .exp-content {
                      padding: 0 10px;

                      &.result {
                        padding: 20px 10px;
                      }
                  }
  
                  table {
                    width: 100%;
                  }
                }
              }
            }
          }
  
          .inner-foot {
            width: 100%;
            padding: 20px;
            margin: 15px auto 15px;
            color: #fff;
            background: #003668;
            border-radius: 10px;
  
            p {
              width: 90%;
              margin: 0 auto;
            }
          }
        }

        &.result-summary-page {
          .section-tit {
            font-size: 18px !important;
            font-weight: 500;
          }
          .result1 {
            .main {
              display: none;
              padding: 40px;
            }

            .explain {
              flex-direction: column;
              box-shadow: none !important;
      
              .exp-tabs {
                width: 100% !important;
                grid-template-rows: 1fr !important;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-column-gap: 10px;
              }
      
              .exp-wrap {
                width: 100%;
                min-width: unset;
                margin-top: 30px;
                padding: 0;
                border: none;
      
                .diet-img {
                  img {
                    max-height: 190px;
                  }
                }
      
                .diet-info {
                  .info-date {
                    .date-tit {
                      flex-direction: column;
                    }
                  }
                  
                  .info-score {
                    flex-direction: column;
      
                    .nut-1, .nut-2 {
                      width: 100%;
                      margin: 0;
                      padding: 0;
                    }
      
                    .line {
                      height: 16px;
                      background: none;
                    }
                  }
                }
      
                .diet-result {
                  justify-content: center;
                }
              }
            }

            .food-est-list {
              .exp-wrap {
                .diet-info {
                  .info-score {
                    flex-direction: column;
        
                    .nut-1, .nut-2 {
                      width: 90%;
                      margin: 0;
                      padding: 0;
                    }
        
                    .line {
                      height: 16px;
                      background: none;
                    }
                  }
                }
      
                .diet-result {
                  justify-content: center;
                }
              }
            }
          }

          .result2 {
            .main {
              background: none !important;
              box-shadow: none !important;

              .explain {
                padding: 40px;
                background: #fff;

                .exp-wrap {
                  padding: 0;
                  border: none;

                  .exp-content {
                    flex-direction: column;

                    .exp-pie-chart {
                      width: 100%;
                      padding: 0;
                    }

                    .pie-chart-result {
                      width: 100%;
                      margin-top: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .survey {
      .table-container {
        .section-answer {
          .selection {
            &.etc {
              align-items: flex-start;
              flex-direction: column;

              .textbox {
                width: 100% !important;
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  
  //모바일 세로
  @include mobile {
    .subheader {
      top: 55px !important;
  }

    .modal-dialog {
      max-width: 100% !important;
  
      .food-input-wrap {
        padding: 20px;
  
        #beforeTime,
        #afterTime {
          width: 50% !important;
        }
      }
  
      .img-input-wrap {
        img {
          width: 70px !important;
          height: 70px !important;
        }
  
        .img-input-label {
          &.new {
            width: 70px !important;
            height: 70px !important;
          }
        }
      }
    }
  
    .tabs-content-init {
      .search-box {
        padding: 20px 30px;
        flex-direction: column;
        align-items: flex-start;
  
        .search-factor {
          .search-tit {
            width: 40px;
          }
  
          .dx-selectbox {
            width: 100px;
          }
  
          form {
            input {
              width: 100px;
            }
          }
  
          & + .search-factor {
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }
  
    .page-wrap {
      .page-tit {
        flex-direction: column;
  
        .data-indate {
          font-size: 14px;
          margin-top: 20px;
          margin-left: auto;
        }
      }
  
      .page-content-wrap {
        &.predict-result,
        &.ogtt-result,
        &.pattern-result,
        &.result-summary-page {
          background: none;
          box-shadow: none;
  
          .result {
            padding: 0;
  
            .section-tit {
              overflow: hidden;
              height: auto;
              font-size: 18px;
  
              &.pc {
                display: none;
              }
  
              &.mobile {
                display: block;
                width: 100%;
                margin: 0 auto 20px;
              }
            }
  
            .section-init {
              flex-direction: column;
  
              .result-section {
                width: 100%;
                box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.15);
  
                &.main {
                  background: #fff;
                  border-radius: 20px;
  
                  .result-bar {
                      width: 100%;
                    padding: 40px;
  
                    .result-score {
                      p {
                        width: 100%;
                      }
                    }
                  }
                }
  
                &.explain {
                  .result-txt {
                    border: none;
                    padding: 0;
  
                    .result-exp {
                      width: 100%;
                      padding: 20px;
                      margin: 0;
                      background: #fff;
                      border-radius: 20px;
                      border: none;
  
                      p {
                        width: 100%;
                        margin: 0 auto 12px;
                        font-size: 16px;
                      }
                    }
  
                    .inner-foot {
                      padding: 20px;
                      margin: 15px auto 15px;
                      color: #fff;
                      background: #003668;
                      border-radius: 20px;
  
                      p {
                        width: 100%;
                        margin: 0 auto;
                      }
                    }
                  }
                }
  
                & + .result-section {
                  margin-top: 15px;
                }
              }
            }
          }
        }

        &.predict-result {
            .result {
                .result-section {
                    &.explain {
                        margin-left: 0 !important;
                        background: #fff;
                        border-radius: 20px !important;
                        padding: 40px !important;
                    }
                }

                &.result1 {
                    .explain {
                        .exp-wrap {
                            &> p {
                                padding: 0 10px;
                            }
                        }
                    }
                }

                &.result2 {
                    margin-top: 15px;

                    .section-tit {
                        &.mobile {
                            margin-bottom: 30px;
                        }
                    }
                }

                &.result3 {
                    margin-top: 15px;
                    background: #fff;
                    border-radius: 20px;
                    padding: 40px;
                    box-shadow: 0px 2px 10px 0px rgba(0,0,0,.15);

                    .result-section {
                        box-shadow: none;
                        .result-txt {
                            flex-direction: column;
                            margin: 0 0 40px;

                            .exp-txt {
                                width: 100%;

                                p {
                                    padding: 0 10px;
                                }
                            }

                            .exp-elem {
                                width: 100%;
                                margin-left: 0;
                                margin-top: 10px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
  
        &.pattern-result {
          .result1 {
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .section-tit {
                    width: 100%;
                    margin: 0 auto 20px;
                  }
  
                  .graph-wrap {
                    img {
                      width: 100%;
                    }
  
                    .polar-chart-tit {
                      margin-bottom: 0;
                    }
                  }

                  .result-section.explain {
                    box-shadow: none;
                    padding: 0;
                  }
                }
  
                &.explain {
                  border: none;
                  background: #fff;
                  border-radius: 20px;
  
                  .exp-wrap {
                    .exp-top {
                      flex-direction: column;
                      margin-bottom: 40px;
  
                      .exp-charts {
                        float: unset;
                        width: 100%;
                      }
  
                      .exp-right {
                        float: unset;
                        width: 100%;
                        padding: 0;
                        margin-top: 20px;
  
                        .exp-txt {
                          line-height: 24px;
  
                          & + .exp-txt {
                            margin-top: 20px;
                          }
                        }
                      }
                    }
                  }
  
                  .exp-score {
                    background: #f9faff;
  
                    .score-set {
                      flex-direction: column;
                      justify-content: unset;
  
                      .txt-a {
                        margin-right: auto;
                      }
  
                      .txt-r {
                        margin-left: auto;
                      }
                    }
                  }
  
                  .factor {
                    display: flex;
                    flex-direction: column;
  
                    .dish-img {
                      width: 100%;
                      padding-right: 0;
                    }
  
                    .factor-exp {
                      width: 100%;
                      padding: 0 10px;
                      margin-top: 30px;
                    }
                  }
                }
              }
            }
          }
  
          .result2 {
            margin-top: 15px;
  
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .graph-wrap {
                    width: 100%;
                    padding: 0;
  
                    h3 {
                      font-size: 16px;
                      margin: 0 0 20px 0;
                    }
                  }
                }
              }
  
              .slide-btn-wrap {
                width: 100%;
                margin-top: 15px;
                border-bottom: none;
  
                .slide-btn {
                  width: 100%;
                  height: 54px;
                  padding-left: 20px;
                  color: #fff;
                  text-align: left;
                  background: #039be5;
                  border-radius: 9px;
  
                  &.active {
                    background: #165b9b;
                  }
  
                  g {
                    rect {
                      fill: #fff;
                    }
                  }
                }
              }
  
              .exp-table-wrap {
                margin-top: 15px;
  
                &.active {
                  width: 100%;
                  background: #fff;
                  padding: 40px;
                  border-radius: 9px;
                  margin-bottom: 15px;
  
                  .col-01 {
                    width: 10%;
                  }
  
                  .col-03 {
                    &.pc {
                      display: none;
                    }
  
                    &.mobile {
                      width: 10%;
                      display: table-cell;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
  
          .result3 {
            width: 100%;
            margin-top: 15px;
            padding: 0 !important;

            .section-tit {
              &.pc {
                display: none;
              }

              &.mobile {
                display: block;
                margin-bottom: 20px;
              }
            }
  
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .graph-wrap {
                    h3 {
                      margin: 0;
                      font-size: 18px;
                    }
                  }
                }
  
                &.explain {
                  padding: 40px;
                  background: #fff;
                  border-radius: 20px;
  
                  .result-exp {
                    .exp-outer {
                      width: 100%;
  
                      .exp-name {
                        font-size: 18px;
                      }
  
                      .exp-wrap {
                        background: #f9faff;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }

          .result4 {
            margin-top: 15px;

            .section-tit {
              &.pc {
                display: none;
              }

              &.mobile {
                display: block;
              }
            }

            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .graph-wrap {
                    h3 {
                      margin: 0;
                      font-size: 18px;
                    }
                  }
                }
  
                &.explain {
                  padding: 40px;
                  background: #fff;
                  border-radius: 20px;
  
                  .result-exp {
                    .exp-outer {
                      width: 100%;
  
                      .exp-name {
                        font-size: 18px;
                      }
  
                      .exp-wrap {
                        background: #f9faff;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
  
          .inner-foot {
            width: 100%;
            padding: 20px;
            margin: 15px auto 15px;
            color: #fff;
            background: #003668;
            border-radius: 20px;
  
            p {
              width: 90%;
              margin: 0 auto;
            }
          }
        }
  
        &.ogtt-result {
          .result {
            .section-init {
              .result-section {
                &.main {
                  padding: 40px;
  
                  .section-tit {
                    width: 100%;
                    margin: 0 auto 30px;
                  }
  
                  .chart-wrap {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
  
                    .google-chart {
                      width: 100%;
                    }
                  }

                  table {
                      width: 100%;
                  }
                }
  
                &.explain {
                  background: #fff;
                  border: none;
                  border-radius: 20px;
                  padding: 40px;

                  .exp-content {
                      padding: 0 10px;

                      &.result {
                        padding: 20px 10px;
                      }
                  }
                }
              }
            }
          }

          .result1 {
            .result-section {
                &.explain {
                    margin: 15px 0 0;
                }
            }
          }
  
          .result2 {
            margin-top: 15px;
  
            .section-init {
              .result-section {
                box-shadow: 0px 2px 10px 0px rgba(0,0,0,.15);

                &.explain {
                  width: 100%;
                  padding: 0;
                  margin: 0 auto;
                  box-shadow: none;
  
                  .section-tit {
                    margin-left: 0;
                  }
  
                  table {
                    width: 100%;
                  }
                }
              }
            }
          }
  
                .inner-foot {
                    width: 100%;
                    padding: 20px;
                    margin: 15px auto 15px;
                    color: #fff;
                    background: #003668;
                    border-radius: 10px;

                    p {
                        width: 90%;
                        margin: 0 auto;
                    }
                }
            }

            &.result-summary-page {
              .section-tit {
                font-size: 18px !important;
                font-weight: 500;
              }

              .result1 {
                .main {
                  display: none;
                  padding: 40px;
                }

                .explain {
                  flex-direction: column;
                  box-shadow: none !important;
          
                  .exp-tabs {
                    width: 100% !important;
                    grid-template-rows: 1fr !important;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-column-gap: 10px;
                  }
          
                  .exp-wrap {
                    width: 100%;
                    min-width: unset;
                    margin-top: 30px;
                    padding: 0;
                    flex-direction: column;
                    border: none;
          
                    .diet-img {
                      img {
                        width: 100%;
                        max-height: 260px;
                        margin: 0 auto;
                      }
                    }
          
                    .diet-info {
                      margin: 10px 0 0;
                      .info-score {
                        flex-direction: column;
          
                        .nut-1, .nut-2 {
                          width: 80%;
                          margin: 0;
                          padding: 0;
                        }
          
                        .line {
                          height: 16px;
                          background: none;
                        }
                      }
                    }
          
                    .diet-result {
                      justify-content: center;
                    }
                  }
                }

                .food-est-list {
                  .exp-wrap {
                    width: 100% !important;
                    padding: 20px !important;
                    flex-direction: column;
                    min-width: unset !important;

                    .diet-img {
                      img {
                        width: 100%;
                        height: 260px !important;
                      }
                    }

                    .diet-info {
                      width: 100%;
                      margin: 10px 0 0;

                      .info-date {
                        .date-tit {
                          flex-direction: column;
                        }

                        .diet-level {
                          margin-top: 10px;
                          justify-content: flex-end;
                        }
                      }

                      .diet-init {
                        flex-direction: column !important;

                        .diet-img {
                          width: 100%;
                        }

                        .info-score {
                          width: 100%;
                          margin: 20px 0;
                          flex-direction: column;
              
                          .nut-1, .nut-2 {
                            width: 80%;
                            margin: 0;
                            padding: 0;
                          }
              
                          .line {
                            height: 16px;
                            background: none;
                          }
                        }
                      }

                      .bloodlevel-change {
                        .nut-2 {
                          .nut-item {
                            align-items: flex-start;
                            flex-direction: column;

                            .nut-i-cont{
                              margin-top: 5px;

                              &::before {
                                content: "";
                              }
                            }
                          }
                        }
                      }
                    }
          
                    .diet-result {
                      justify-content: center;
                    }
                  }
                }
              }

              .result2 {
                .main {
                  background: none !important;
                  box-shadow: none !important;

                  .explain {
                    padding: 40px;
                    border-radius: 20px;
                    background: #fff;

                    .exp-wrap {
                      padding: 0;
                      border: none;

                      .exp-content {
                        flex-direction: column;

                        .exp-linear-chart {
                          flex-direction: column;

                          .exp-chart {
                            width: 100%;
                          }

                          .exp-txt {
                            width: 100%;
                            margin-left: 0;
                            margin-top: 10px;

                            &> div {
                              padding: 10px 0;
                            }
                          }
                        }

                        .exp-pie-chart {
                          width: 100%;
                          padding: 0;
                        }

                        .pie-chart-result {
                          width: 100%;
                          margin-top: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
        }
    }

    .survey {
      .table-container {
        .section-answer {
          .selection {
            &.etc {
              align-items: flex-start;
              flex-direction: column;

              .textbox {
                width: 100% !important;
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
}
