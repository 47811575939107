
.page-wrap.predict {
    
//문항 테이블 레이아웃
    .filter-table {
        width: 96%;
        margin: 0 auto 60px;


        tr {
            td {
                &.calc-data {
                    input {
                        background: #f2f7ff;
                        border: 1px solid #6e6e6e;
                    }
                }

                label {
                    color: rgb(78, 78, 78);
                    font-size: 1.4rem;
                }
            }


//항목 Row 간격
            &+ tr {
                margin-top: 24px;
            }
        }


//3단 그리드
        &.tri {
            tr {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 5%;
            }
        }


//4단 그리드
        &.quat {
            tr {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-column-gap: 2.5%;
            }
        }
    }
}