.page-content-wrap.ogtt {
  .result {
    .section-init {
      //형당반응 유형 분석
      .page-break {
        .result-txt {
          width: 100%;
        }
      }

      //혈당반응 유형 차트
      .result-bar {
        &.ogtt-chart {
          overflow: hidden;
          width: 80%;
          height: auto;
          margin: 0 auto;

          &.print {
            height: 0;
          }

          .google-chart {
            display: block;
            float: left;
            width: 50%;
            height: 400px;
          }
        }

        &.img {
          .exp-img-chart {
            position: relative;
            display: flex;
            padding: 0 0 20px 25px;
            align-items: center;
            justify-content: center;

            img {
              width: 85%;
              object-fit: contain;
              object-position: center;
            }

            .img-y {
              position: absolute;
              left: -20px;
              transform: rotate(-90deg);
              letter-spacing: -0.07rem;
            }

            .img-x {
              position: absolute;
              bottom: 0px;
              letter-spacing: -0.07rem;
            }
          }
        }
      }

      //해당 유형 퍼센트 테이블
      .ogtt-table {
        width: 80%;
        margin: 0 auto 30px;
        border-collapse: collapse;

        th {
          background-color: #f5f5f5;
          padding: 10px;
          border: 1px solid #e7e7e7;
          text-align: center;
          font-size: 1.4rem;

          &.on {
            background: #039be5;
            color: #fff;
          }
        }

        td {
          padding: 10px;
          border: 1px solid #e7e7e7;
          text-align: center;
          font-size: 1.4rem;

          &.on {
            color: #039be5;
            font-weight: 500;
            font-size: 16px;
          }
        }

        //앞으로의 운명 테이블
        &.move {
          width: 100%;
        }
      }

      .section-explain {
        .result-exp {
          p {
            &.bold {
              width: 100% !important;
              font-weight: 500;
              margin: 40px 0 10px;
            }
          }
        }
      }
    }
  }
}
