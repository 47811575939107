
.page-wrap.ogtt {

//Full-size 인풋 페이지 타이틀
    .section-tit {
        margin-bottom: 4px !important;
        border-bottom: none !important;
    }


//테이블 레이아웃
    .filter-table {
        overflow: auto;
        width: 100%;
        font-size: 1.6rem;
        border: 1px solid #a1a1a1;
        border-radius: 0.42rem;

        table{
            width: 100%;
            border-collapse: collapse;

            thead {
                th {
                    height: 45px;
                    color: #fff;
                    font-size: 1.6rem;
                    font-weight: 500;
                    text-align: center;
                    border-bottom: 1px solid #fff;
                    background-color: #808080;

                    &:nth-of-type(2n-1) {
                        background-color: #686868;
                    }

                    &:first-child {
                        background-color: #686868;
                        border-color: #fff;
                    }
                }
            }

            tbody {
                tr {
                    height: 80px;
                    text-align: center;

                    td {
                        border-top: 1px solid #a1a1a1;
                        
                        &:nth-of-type(2n-1) {
                            background-color: #f7f7f7;
                        }

                        &:first-child {
                            color: #fff;
                            font-weight: 500;
                            background-color: #808080;
                            border-color: #fff;
                        }

                        input {
                            width: 80%;
                            height: 40px;
                            margin: 0 auto;
                            font-size: 1.6rem;
                            border: 1px solid #d8d8d8;
                            border-radius: 0.42rem;
                        }
                    }
                }
            }
        }
    }
}