// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "../_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "../_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "../_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "../_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "../_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "../_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "../_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

:root {
  --btn-color: #dc004e;
  --btn--hover-color: #f24482;
  --text-color: #337ab7;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #f0f0f0;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 16px;
}

.react-button {
  width: 200px;
  position: relative;
  overflow: hidden;
  outline: none;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 2px;
  padding: 8px 16px;
  margin: 8px;
  font-size: 14px;
  font-family: inherit;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.2s;
  display: flex;
  align-items: center;
}

.react-button {
  background: var(--btn-color);
}
.react-button:hover {
  background-color: var(--btn--hover-color);
}

.material-loader {
  fill: none;
  stroke-width: 6;
  -webkit-animation: rotate 1000ms linear infinite;
  animation: rotate 1000ms linear infinite;
}

.material-loader__circle {
  stroke: #fff;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1500ms ease-in-out infinite;
  animation: dash 1500ms ease-in-out infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.25);
    transform: rotate(0deg) scale(0.25);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.25);
    transform: rotate(360deg) scale(0.25);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.4);
    transform: rotate(0deg) scale(0.4);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.4);
    transform: rotate(360deg) scale(0.4);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
