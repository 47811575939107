
.page-content-wrap.pattern {
    .section-init {
        .result-bar {


//Cluster 이미지
            &.cluster {
                .cluster-wrap {
                    display: flex;
                    margin: 0 auto;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    img {
                        width: 100%;
                        max-height: 340px;
                        object-fit: contain;
                        object-position: center;
                    }

                    div {
                        font-size: 1.4rem;
                    }
                }
            }


//접시법 이미지
            &.dishes {
                display: flex;


                img {
                    width: 92%;
                    object-fit: contain;

                    &.fork {
                        width: 8%;
                    }
                }
            }


//영양소 섭취 비율
            &.pie {
                display: flex;
                flex-direction: column;
            }
        }

    .section-explain {


//클러스터 패턴 예측그룹
            .result-exp {
                .result-score {
                    overflow: hidden;
                    width: 100%;
                    height: auto;
                    margin-top: 30px;
                    padding: 15px 20px;
                    letter-spacing: -1px;
                    vertical-align: middle;
                    background: #fafafa;
                    border: 1px solid #ececec;
                    border-radius: 0.42rem;

                    .score-set {
                        display: flex;
                        overflow: hidden;
                        width: 100%;
                        height: auto;
                        color: #000;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-between;
            
                        & + .score-set {
                            margin-top: 10px;
                        }
            
                        .txt-a {
                            width: 100%;
                            font-size: 1.4rem;
                            margin: 0;
                        }
            
                        .txt-r {
                            width: 100%;
                            text-align: right;
                            font-size: 1.8rem;
                            margin: 0;
            
                            span {
                                display: inline-block;
                                margin-left: 10px;
                                color: #039be5;
                            }
                        }
                    }
                }


//영양소 섭취비율 설명
                .exp-outer {
                    &+ .exp-outer {
                        margin-top: 20px;
                    }

                    .exp-name {
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    .exp-elem {
                        span {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }


//영양소 섭취 평가 Slide 버튼
        .slide-btn-wrap {
            width: calc(100% - 80px);
            margin: 30px auto 0;
            border-bottom: 2px solid #d8d8d8;

            .slide-btn {
                width: 100%;
                height: 45px;
                padding-left: 10px;
                color: #676767;
                font-size: 18px;
                text-align: left;
                letter-spacing: -1px;
                border: none;
                background: none;
                cursor: pointer;
        
                .minus {
                    display: none;
                }
        
                &.active {
                    .minus {
                        display: inline;
                    }
        
                    .plus {
                        display: none;
                    }
                }
            }
        }


//영양소 섭취 평가 영양소 테이블
        .exp-table-wrap {
            width: calc(100% - 120px);
            max-height: 0px;
            opacity: 0;
            visibility: hidden;
            margin: 20px auto 0;
            transition: all 0.3s linear;
    
            &.active {
                max-height: none;
                opacity: 1;
                visibility: visible;
                margin: 40px auto 0;
            }
    
            table {
                border-collapse: collapse;
    
                td {
                    padding: 10px;
                    border: 1px solid #e7e7e7;
                    font-size: 1.4rem;
    
                    .print-hide {
                        color: #039be5;
                        font-weight: 600;
                        cursor: pointer;
                    }

                    .print {
                        display: none;
                    }
                }
    
                th {
                    text-align: center;
                    background: #f5f5f5;
                    border: 1px solid #e7e7e7;
                }
    
                tbody {
                    .col-01 {
                        width: 10%;
                        text-align: center;
                    }
    
                    .col-03 {
                        padding: 10px 20px;
                    
                        &.pc {
                            display: table-cell;
                        }
                
                        &.mobile {
                            display: none;
                        }
                    }
    
                    .col-04 {
                        width: 10%;
                        text-align: center;
                    }
                }
            }
        }
    }
}