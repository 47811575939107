
//버튼 위치 조정(중앙정렬)
.btn-set {
    display: flex;
    align-items: center;
    justify-content:center;

    &.result {
        margin: 30px 0;
    }

    &.bottom-line {
        justify-content: flex-end;
    }
}


//버튼 공통
.btn {
    display: flex !important;
    cursor: pointer;
    padding: 4px;
    border-radius: 0.42rem;
    align-items: center;
    justify-content: center;


//버튼부 색상
    &.color-primary {
        background: #0072db;
    
        &:hover {
            background: #0067c7;
        }
    
        &:active {
            background: #0067c7;
        }
    }
    
    &.color-second {
        background: #005cb0;
    
        &:hover {
            background: #0054a3;
        }
    
        &:active {
            background: #00519c;
        }
    }
    
    &.color-third {
        background: #00498c;
    
        &:hover {
            background: #004483;
        }
    
        &:active {
            background: #013e77;
        }
    }
    
    &.color-forth {
        background: #003668;
    
        &:hover {
            background: #002f5c;
        }
    
        &:active {
            background: #002b53;
        }
    }
    
    &.color-light {
        background: #2090ff;
    
        &:hover {
            background: #187fe6;
        }
    
        &:active {
            background: #1573d1;
        }
    }
    
    &.color-dark {
        background: #484848;
    
        &:hover {
            background: #3f3f3f;
        }
    
        &:active {
            background: #2e2e2e;
        }
    }
    
    &.color-grey {
        background: #747474;
    
        &:hover {
            background: #5f5f5f;
        }
    
        &:active {
            background: #505050;
        }
    }
    
    &.color-red {
        background: #dd3d3d;
    
        &:hover {
            background: #ce3434;
        }
    
        &:active {
            background: #bd2828;
        }
    }
    
    &.color-green {
        background: rgb(55, 173, 154);
    
        &:hover {
            background: rgb(46, 156, 138);
        }
    
        &:active {
            background: rgb(39, 143, 125);
        }
    }

    &.w-100 {
        width: 100%;
    }

    &.w-120px {
        width: 120px;
    }

    &.h-45 {
        height: 45px;
    }


//버튼 내 아이콘 색상 설정
    .svg-icon-lg {
        svg {
            width: 18px;
            height: 18px;

            g {
                path {
                    fill: #fff;
                }

                #Combined-Shape-Copy, #Rectangle {
                    fill: #fff;
                }
            }
        }
    }
}


//기타 색상
.color-primary {
    background: #0072db;

    &:hover {
        background: #0067c7;
    }

    &:active {
        background: #0067c7;
    }
}

.color-second {
    background: #005cb0;

    &:hover {
        background: #0054a3;
    }

    &:active {
        background: #00519c;
    }
}

.color-third {
    background: #00498c;

    &:hover {
        background: #004483;
    }

    &:active {
        background: #013e77;
    }
}

.color-forth {
    background: #003668;

    &:hover {
        background: #002f5c;
    }

    &:active {
        background: #002b53;
    }
}

.color-light {
    background: #2090ff;

    &:hover {
        background: #187fe6;
    }

    &:active {
        background: #1573d1;
    }
}

.color-dark {
    background: #484848;

    &:hover {
        background: #3f3f3f;
    }

    &:active {
        background: #2e2e2e;
    }
}

.color-grey {
    background: #747474;

    &:hover {
        background: #5f5f5f;
    }

    &:active {
        background: #505050;
    }
}

.color-red {
    background: #dd3d3d;

    &:hover {
        background: #ce3434;
    }

    &:active {
        background: #bd2828;
    }
}

.color-green {
    background: rgb(55, 173, 154);

    &:hover {
        background: rgb(46, 156, 138);
    }

    &:active {
        background: rgb(39, 143, 125);
    }
}


