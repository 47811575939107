//탭 뒷면 페이지 배경색 제거
.page-content-wrap.foodblood {
  background: none !important;
  box-shadow: none !important;
}

//상단 탭버튼
.page-tabs-wrap {
  position: relative;

  .page-tabs {
    display: flex;
    margin: 0;
    align-items: flex-end;

    .page-tab-item {
      display: flex;
      width: 14%;
      height: 30px;
      color: #fff;
      font-size: 1.4rem;
      text-align: center;
      border-radius: 0.42rem 0.42rem 0 0;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & + .page-tab-item {
        margin-left: 2px;
      }

      &.isActive {
        width: 24%;
        height: 46px;
        color: #3f3f3f;
        font-size: 1.6rem;
        font-weight: 500;
        background: #fff;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);

        &:hover {
          background: #3f3f3f;
          background: #fff;
        }
      }

      &.food {
        background: #003668;

        &:hover {
          background: #002c55;
        }
      }

      &.blood {
        background: #005cb0;

        &:hover {
          background: #0053a1;
        }
      }

      &.canpro {
        background: #0072db;

        &:hover {
          background: #006dd3;
        }
      }
    }

    .result-summary {
      padding: 0;
      margin-left: auto;
      margin-bottom: 10px;
      background: #484848;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 5px 20px;
        color: #fff;
        font-size: 1.5rem;
      }

      &:hover {
        background: #3f3f3f;

        a {
          color: #fff;
        }
      }

      &:active {
        background: #2e2e2e;

        a {
          color: #fff;
        }
      }
    }
  }
}

//탭 인덱스 페이지
.tabs-content {
  position: relative;
  padding: 30px;
  background: #fff;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.15);
}
