// 헤더
.header {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;

  .menu-nav {
    .menu-item {
      .menu-link {
        display: flex;
        align-items: center !important;
        justify-content: center;

        &.active {
          height: 65px !important;

          .menu-text {
            font-weight: bold !important;
            color: #fff !important;
          }
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          object-position: center;
        }

        .menu-text {
          font-size: 1.6rem !important;
          color: rgba(255, 255, 255, 0.6) !important;
        }

        &:hover {
          .menu-text {
            color: rgba(255, 255, 255, 1) !important;
          }
        }
      }
    }
  }

  // &.main {
  //   background: none !important;
  //   box-shadow: none !important;

  //   .mainmenu-side-icon {
  //     display: block;
  //   }

  //   .submenu-side-icon {
  //     display: none;
  //   }

  //   .menu-text {
  //     color: #434343 !important;
  //   }

  //   .header-user {
  //     &:hover {
  //       background: #e5e7f2 !important;
  //     }

  //     .mainmenu-user-icon {
  //       display: block;
  //     }

  //     .submenu-user-icon {
  //       display: none;
  //     }

  //     .user-text {
  //       color: #434343;
  //     }
  //   }
  // }

  &.main-user {
    display: none !important;
  }

  &.main {
    background: #0072db;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15) !important;

    .submenu-side-icon {
      display: block;
    }

    .mainmenu-side-icon {
      display: none;
    }

    .menu-link {
      &:hover {
        background: rgba(0, 0, 0, 0.3) !important;
      }

      .menu-text {
        color: #fff !important;
      }
    }

    .header-user {
      height: 37px !important;

      &:hover {
        background: rgba(0, 0, 0, 0.3) !important;
      }

      .mainmenu-user-icon {
        display: none;
      }

      .submenu-user-icon {
        display: block;
        width: 14px;
        height: 14px;
        margin: 0 8px;
      }

      .user-text {
        color: #fff;
        font-size: 1.4rem;
      }
    }
  }
}

//서브헤더
.subheader {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 65px;
  z-index: 1;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  background: #fff;

  &.main {
    display: none;
  }

  &.sub {
    height: 54px;
  }

  .breadcrumb-item-main {
    margin-right: 20px;
  }

  .breadcrumb-item {
    font-size: 1.5rem;

    &::after {
      padding-left: 2px !important;
      color: #85858d;
      line-height: 2.68rem;
    }

    &:hover {
      a {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 0.42rem;
      }
    }

    & + .breadcrumb-item {
      padding-left: 2px !important;
    }

    .breadcrumb-item-active {
      color: #0072db !important;
    }

    a {
      padding: 2px 6px;
    }
  }
}
