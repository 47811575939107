.print-page-wrap {
  width: 980px;
  padding: 25px !important;
  margin: 0 auto !important;
  background: #eef0f8;
  border-radius: 0.42rem;

  .print-hide {
    display: none;
    max-height: 0 !important;
    margin-bottom: 0 !important;
  }

  .print {
    display: block !important;
  }

  //페이지 타이틀 하단 마진조정
  .section-tit {
    margin-bottom: 20px !important;
  }

  //연속혈당기반 결과페이지
  //식후 혈당 변화에 따른 식단 평가
  .food-est-wrap {
    display: block !important;
  }

  .food-est-init {
    overflow: hidden;
    display: block !important;
    max-height: fit-content;
    flex-direction: column;
    background: #fff;
    cursor: unset !important;
    page-break-inside: auto;
    break-inside: auto;
    -webkit-region-break-inside: auto;

    & + .food-est-init {
      padding-top: 10mm;
      page-break-before: always;

      &.invisible {
        padding-top: 0 !important;
        page-break-before: avoid !important;
      }
    }

    &.invisible {
      & + .food-est-init {
        margin-top: 0;
        padding-top: 0 !important;
        page-break-before: avoid !important;
      }
    }

    &.good {
      .diet-list {
        border: 1px solid #00cab2 !important;
        border-radius: 0 0 6px 6px;
      }
    }

    &.fine {
      .diet-list {
        border: 1px solid #33b900 !important;
        border-radius: 0 0 6px 6px;
      }
    }

    &.worried {
      .diet-list {
        border: 1px solid #febb01 !important;
        border-radius: 0 0 6px 6px;
      }
    }

    &.caution {
      .diet-list {
        border: 1px solid #ff8a01 !important;
        border-radius: 0 0 6px 6px;
      }
    }

    &.bad {
      .diet-list {
        border: 1px solid #ec0c00 !important;
        border-radius: 0 0 6px 6px;
      }
    }

    .food-est-level {
      border-radius: 6px 6px 0 0;

      .level-exp {
        display: block !important;
        margin-top: 10px;
        padding: 0 5px;
        line-height: 2.4rem;
        font-size: 1.4rem;
      }
    }

    .diet-list {
      overflow: hidden;
      width: 100%;
      display: block !important;
      height: auto !important;
      padding: 0 5mm !important;
      flex-direction: column !important;
      border: none;

      .exp-wrap {
        display: block !important;
        width: 100% !important;
        min-width: unset !important;
        height: fit-content !important;
        margin: 2.5mm;
        padding: 4mm !important;
        border: none !important;
        background: none !important;
        page-break-inside: avoid !important;
        break-inside: avoid !important;
        -webkit-region-break-inside: avoid !important;

        & + .exp-wrap {
          border-top: 1px solid #ececec !important;
          padding-top: 8mm !important;
        }

        .diet-info {
          flex-direction: row !important;

          .print-layout {
            display: flex;
            width: calc(60%);
            padding-right: 5mm;
            flex-direction: column;

            .info-date {
              .food-list {
                border: 1px solid #ececec;
                background: #fafafa !important;
              }

              .diet-level {
                .level-txt {
                  font-size: 14px !important;
                }
              }
            }

            .nut-item {
              span {
                font-size: 14.8px !important;
              }
            }
          }

          .bloodlevel-change {
            overflow: hidden;
            display: flex;
            width: 40%;
            height: auto;
            margin-top: 0 !important;
            padding: 0 !important;
            border: none !important;

            .print {
              height: unset !important;
            }

            .nut-2 {
              width: 90% !important;
              align-items: center;
              justify-content: center;

              .nut-item {
                span {
                  font-size: 14.8px !important;
                }

                .nut-i-level {
                  margin-left: 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  //연속혈당기반 결과페이지
  //평균 칼로리 섭취량
  .cal-status {
    .cal-info,
    .cal-suggest,
    .divide span {
      font-size: 19px !important;
    }
  }

  //프린트시 폰트사이즈 지정
  .p1 {
    font-size: 28px !important;
  }

  .p2 {
    font-size: 26px !important;
  }

  .bar-tit {
    font-size: 23.2px !important;
  }

  .level-exp {
    font-size: 16px !important;
  }

  .date-txt {
    font-size: 20.4px !important;
  }

  .food-list {
    font-size: 17.6px !important;
  }

  .result-exp {
    p {
      font-size: 20.4px !important;
      line-height: 34.4px !important;
    }

    .exp-outer {
      .exp-name {
        font-size: 20.4px !important;
      }

      .exp-elem {
        font-size: 19px !important;
        line-height: 34.4px !important;
      }
    }
  }
}
