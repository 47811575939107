@import './variables';

@mixin mobile {
    @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-mobile-lg - 1px}) {
        @content;
    }
}

@mixin mobile-lg {
    @media (min-width: #{$breakpoint-mobile-lg}) and (max-width: #{$breakpoint-tablet - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
    }
}


@mixin metronic {
    @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-metronic - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$breakpoint-metronic}) and (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
    }
}
