@media print and (color) {
  * {
    -webkit-print-color-adjust: exact !important;
    -webkit-filter: opacity(1) !important;
  }
}

@media print {
  @page {
    size: A4 landscape;
    margin: 3mm;
  }

  html {
    &.diabetes {
      font-size: 14px !important;
      font-family: Spoqa Han Sans !important;
    }
  }

  body {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Spoqa Han Sans !important;
  }

  // 배경색 삭제 및 결과 border 생성
  // body {
  //   background: #fff !important
  // }

  // .result {
  //   border: 1px solid rgba(0,0,0,.1) !important;
  // }

  // 프린트 시 안보이는 요소 처리
  .print-hide {
    display: none !important;
    height: 0 !important;
    page-break-after: auto !important;
  }

  // 프린트 시 보이는 요소 처리
  .print {
    display: block !important;
  }

  .page-wrap {
    margin: 0 25px 25px !important;
  }

  //페이지 넘기기

  .section-init {
    &.page {
      display: block !important;
    }
  }

  .page-break {
    page-break-before: always !important;
  }

  //그래프 사이즈 A4페이지로 맞춤
  .result-bar {
    &.linear {
      .item-tit {
        min-width: 132px !important;
        font-size: 1.2rem !important;
      }
    }

    &.ogtt-chart {
      width: 100% !important;

      &.print {
        display: flex !important;
        height: unset !important;
        flex-direction: row;

        .google-chart {
          div {
            height: 400px !important;

            svg {
              height: 400px !important;
            }
          }
        }
      }
    }

    &.pie {
      .print {
        display: block !important;
      }
    }
  }

  //페이지 타이틀 하단 마진조정
  .section-tit {
    margin-bottom: 10px !important;
  }

  //임상정보기반 & 혈당모델링기반 상단 입력정보
  .answer-table {
    .row-header {
      width: 180px !important;
    }

    .row-rows {
      div {
        height: 100px !important;
        min-width: 180px;

        span {
          display: inline-block;
          width: 100% !important;
          min-width: 180px !important;
        }
      }
    }
  }

  //식이패턴기반 결과페이지
  //일일 권장 섭취량 대비 섭취 비율 자세히보기 OPEN
  .exp-table-wrap {
    max-height: none !important;
    opacity: 1 !important;
    visibility: visible !important;
    margin: 10mm auto 20mm !important;

    table {
      width: 100% !important;

      .col-print {
        &.col-01 {
          width: 8% !important;
        }

        &.col-02 {
          width: 45% !important;
        }

        &.col-03 {
          width: 15% !important;
        }
      }
    }
  }

  // 연속혈당기반 인쇄 미리보기
  .modal-wrap {
    .print {
      .print-page-wrap {
        margin: 0 !important;
        width: 100% !important;
      }
    }
  }
}

//page-break-before:avoid => 페이지 넘기기 금지
//page-break-inside:avoid => 페이지 넘기기 금지
//https://nasanasa.tistory.com/490
//http://www.incodom.kr/Designing_For_Print_With_CSS
//page-break-before:always => 페이지 분할(이전)
