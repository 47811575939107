//Button
.btn {
  cursor: pointer;
  padding: 4px;

  &.btn-main {
    color: #fff;
    font-size: 1.4rem;
    background: #0072db;

    &:hover {
      color: #fff;
      background: #0067c7;
    }

    &:active {
      color: #fff;
      background: #005eb6;
    }
  }

  &.btn-login {
    width: 100%;
  }

  &.btn-sub {
    color: #fff;
    font-size: 1.1rem;
    background: #f6b100;

    &:hover {
      color: #fff;
      background: #f6a000;
    }

    &:active {
      color: #fff;
      background: #e68a00;
    }

    &.form {
      margin-left: 15px;

      &.id-confirm {
        width: 34%;
      }

      &.zip {
        width: 50%;
      }
    }
  }

  &.btn-cancel {
    padding: 0;
    color: #fff;
    font-size: 1.1rem;
    background: #bdbdbd;

    &:hover {
      color: #fff;
      background: #adadad;
    }

    &:active {
      color: #fff;
      background: #9c9c9c;
    }

    a {
      display: inline-block;
      width: 100%;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  &.btn-line {
    height: 32px;
    width: 60px;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 32px;
    font-size: 1.2rem;
    border-bottom: 1px solid #676767;
    border-radius: 0;

    a {
      display: inline-block;
      height: 32px;
      width: 60px;
      color: #676767;
      cursor: pointer;

      &:hover {
        color: #676767;
      }
    }
  }

  &.btn-survey {
    width: 150px;
    height: 45px;
    padding: 0;
    color: #fff;
    font-size: 16px;
    background: #0072db;

    &:hover {
      color: #fff;
      background: #0067c7;
    }

    &:active {
      color: #fff;
      background: #005eb6;
    }
  }

  &.btn-survey-submit {
    width: 150px;
    height: 45px;
    padding: 0;
    color: #fff;
    font-size: 16px;
    background: #0072db;

    &:hover {
      color: #fff;
      background: #0067c7;
    }

    &:active {
      color: #fff;
      background: #005eb6;
    }
  }

  &.btn-data-insert {
    width: 140px;
    margin-left: auto;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

    &.excel {
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.download {
        width: 180px;
        margin-left: 10px;
      }

      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }

  &.btn-data-detail {
    color: #fff;
    font-weight: 500;
    background: #0f8ae2;
    // background: #0072db;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      color: #fff;
      background: #0b7ccc;
      // background: #0067c7;
    }

    &:active {
      color: #fff;
      background: #076eb8;
      // background: #005eb6;
    }
  }

  &.btn-data-delete {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: #ce0f0f;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

    &.sm {
      width: 100px;
    }

    &:hover {
      color: #fff;
      background: #a80f0f;
    }

    &:active {
      color: #fff;
      background: #960f0f;
    }
  }

  &.btn-delete {
    color: #fff;
    background: #646464;

    &:hover {
      color: #fff;
      background: #535353;
    }

    &:active {
      color: #fff;
      background: #414141;
    }
  }

  &.btn-blood-detail {
    color: white;
    background: #0072db;
    // background: #009f83;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      color: #fff;
      background: #0066c5;
      // background: #008f75;
    }

    &:active {
      color: #fff;
      background: #005baf;
      // background: #00816a;
    }
  }

  &.btn-result {
    width: 150px;
    height: 45px;
    margin-top: 30px;
    color: white;
    font-size: 16px;
    background: #484848;

    &:hover {
      color: #fff;
      background: #3f3f3f;
    }

    &:active {
      color: #fff;
      background: #2e2e2e;
    }
  }

  &.brand-toggle {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    .brand-tit {
      float: left;
      color: #fff;
      font-size: 18px;
    }

    .svg-icon {
      float: right;
      margin-right: 0 !important;

      &.minimize-icon {
        display: none;
      }

      &.full-icon {
        display: inline;
      }
    }

    &.active {
      display: flex;
      float: unset;
      justify-content: center;

      .brand-tit {
        display: none;
      }

      .full-icon {
        display: none;
      }

      .minimize-icon {
        display: inline;

        svg {
          transform: unset !important;
        }
      }
    }
  }

  &.btn-search {
    width: 80px;
    height: 34px;
    color: #fff;
    font-size: 16px;
    line-height: 0;
    font-weight: 500;
    background: #0072db;

    &:hover {
      color: #fff;
      background: #0067c7;
    }

    &:active {
      color: #fff;
      background: #0061bd;
    }
  }

  &.btn-close {
    width: 80px;
    margin-right: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: #0d3153;

    &:hover {
      color: #fff;
      background: #092744;
    }

    &:active {
      color: #fff;
      background: #051c31;
    }
  }

  &.btn-submit {
    &.submit-modal {
      min-width: 80px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      background: #0072db;

      &:hover {
        color: #fff;
        background: #0067c7;
      }

      &:active {
        color: #fff;
        background: #0061bd;
      }

      &:disabled {
        cursor: unset;

        &:hover {
          background: #0f8ae2;
        }

        &:active {
          background: #0f8ae2;
        }
      }
    }
  }

  &.btn-history {
    width: 100px;
    height: 42px;
    padding: 0;
    font-size: 16px;
    color: #fff;
    background: #484848;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      color: #fff;
      background: #3d3d3d;
    }

    &:active {
      color: #fff;
      background: #353535;
    }
  }

  &.btn-reset {
    margin-left: 5px;
    width: 80px;
    height: 34px;
    padding: 0.4rem;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: #e0204a;

    &:hover {
      color: #fff;
      background: #ca193f;
    }

    &:active {
      color: #fff;
      background: #af1435;
    }
  }
}

.btn-line-wrap {
  padding: 0 5px 5px;

  &:hover {
    background: rgb(236, 236, 236);
    border-radius: 5px;
  }
}

// 엑셀 업로드 Modal
.modal-content {
  .uploadfilecontainer {
    height: 120px;
    width: 100%;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #ececec;
    border-radius: 10px;
    background: #f9fbff;

    &:hover {
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    }

    &:active {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    }

    .container {
      width: 100%;
      height: 100px;
      padding: 10px;

      .dropzone {
        display: flex;
        height: 100px;
        border-radius: 5px;
        border: 1px dashed #ddd;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dropZone-tit {
          display: flex;
          margin-bottom: 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .dropZone-tit-img {
            width: 40px;
            height: 40px;
            margin-bottom: 6px;
            background: url(http://pnuh.insystem.kr:20080/cloud_960_720.1a438fc547c244b1b37a.png)
              no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }
    }
  }
}

//결과입력 로딩 팝업
.loading-wrap {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  align-items: center;
  justify-content: center;
  z-index: 999;

  &.result {
    background-color: rgba(255, 255, 255, 0.8);
    $color: #8c6ff0;
    $colorRight: #5628ee;
    $colorLeft: #23c4f8;

    .circle {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: #1e90ff;
      margin-bottom: 25px;
      position: relative;
    }

    .loading-cont {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .rowc {
      display: inline-block;
      margin-left: 2px;
    }

    .c1 {
      animation: c1 1.5s linear infinite;
    }

    .c2 {
      animation: c2 1.5s linear infinite;
      background-color: #3fd1ff;
    }

    @keyframes c1 {
      0% {
        transform: translateY(0px) scale(1);
      }
      25% {
        transform: translateY(12px) scale(1.5);
        background-color: #0c87ff;
        z-index: 10;
      }
      50% {
        transform: translateY(34px) scale(1);
      }
      75% {
        transform: translateY(12px) scale(0.6);
        background-color: #3a9eff;
        z-index: 1;
        opacity: 0.1;
      }
      100% {
        transform: translateY(0px) scale(1);
      }
    }

    @keyframes c2 {
      0% {
        transform: translateY(0px) scale(1);
      }
      25% {
        transform: translateY(-12px) scale(0.6);
        background-color: #75deff;
        z-index: 1;
        opacity: 0.1;
      }
      50% {
        transform: translateY(-34px) scale(1);
      }
      75% {
        transform: translateY(-12px) scale(1.5);
        background-color: #0fc3ff;
        z-index: 10;
      }
      100% {
        transform: translateY(0px) scale(1);
      }
    }

    .row2 .c1 {
      animation-delay: 0.1s;
    }
    .row2 .c2 {
      animation-delay: 0.1s;
    }
    .row3 .c1 {
      animation-delay: 0.22s;
    }
    .row3 .c2 {
      animation-delay: 0.22s;
    }
    .row4 .c1 {
      animation-delay: 0.37s;
    }
    .row4 .c2 {
      animation-delay: 0.37s;
    }
    .row5 .c1 {
      animation-delay: 0.49s;
    }
    .row5 .c2 {
      animation-delay: 0.49s;
    }
    .row6 .c1 {
      animation-delay: 0.67s;
    }
    .row6 .c2 {
      animation-delay: 0.67s;
    }
    .row7 .c1 {
      animation-delay: 0.89s;
    }
    .row7 .c2 {
      animation-delay: 0.89s;
    }
    .row8 .c1 {
      animation-delay: 0.95s;
    }
    .row8 .c2 {
      animation-delay: 0.95s;
    }
    .row9 .c1 {
      animation-delay: 1.2s;
    }
    .row9 .c2 {
      animation-delay: 1.2s;
    }
    .row10 .c1 {
      animation-delay: 1.45s;
    }
    .row10 .c2 {
      animation-delay: 1.45s;
    }
    .row11 .c1 {
      animation-delay: 1.62s;
    }
    .row11 .c2 {
      animation-delay: 1.62s;
    }
    .row12 .c1 {
      animation-delay: 1.88s;
    }
    .row12 .c2 {
      animation-delay: 1.88s;
    }
    .row13 .c1 {
      animation-delay: 2s;
    }
    .row13 .c2 {
      animation-delay: 2s;
    }

    .load-txt {
      text-align: center;
      font-size: 1.4rem;
    }
  }

  .loading-up {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 140px;
    background: #fff;
    border-radius: 5px;
    border: none;
    flex-direction: column;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

    .spinner {
      margin-top: 10px;
    }

    .loading-txt {
      margin-top: 24px;
      font-size: 16px;
    }
  }
}

//프린트 로딩 팝업
.progress-print {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #eef0f8;
  border-radius: 4px;

  .progress-print-txt {
    margin-bottom: 10px;
    font-size: 1.4rem;
    letter-spacing: -0.7px;
  }

  .progress-print-bg {
    width: 80%;
    max-width: 260px;
    height: 6px;
    background: #e1e4e8;
    border-radius: 3px;
    overflow: hidden;

    .progress-print-bar {
      display: block;
      height: 100%;
      background: linear-gradient(
        90deg,
        #ffd33d,
        #ea4aaa 17%,
        #b34bff 34%,
        #01feff 51%,
        #ffd33d 68%,
        #ea4aaa 85%,
        #b34bff
      );
      // background: linear-gradient(90deg,#2405ac,#3a9eff 17%,#75deff 34%,#6bffff 51%,#0fc3ff 68%,#207ffc 85%,#6200ff);
      background-size: 300% 100%;
      animation: progress-animation 2s linear infinite;
    }

    @keyframes progress-animation {
      0% {
        background-position: 100%;
      }

      100% {
        background-position: 0;
      }
    }
  }
}

//메세지 창
.message-wrap {
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .message {
    display: inline;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px 80px;

    span {
      display: inline-block;
      width: 100%;
      text-align: center;

      &.message-txt {
        margin-top: 10px;
        font-size: 1.3rem;
      }
    }
  }
}

.search-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
