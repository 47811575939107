//메인화면 메뉴
.mainmenu-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 0 10px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

//메인화면 버튼
.main-btn-set {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    display: flex;
    width: 260px;
    min-height: 40%;
    padding: 24px 36px 60px;
    border-radius: 0.42rem 0.42rem 120px 0.42rem;
    box-shadow : 0 2px 12px 0 rgba(0,0,0,.25);
    flex-direction: column;
    margin-right: 10px;
    margin-top: 10px;

    a {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: left;
        cursor: pointer;
    }

    &:hover {
        box-shadow: 0 1px 15px 0 rgba(0,0,0,.5);
    }

    &:active {
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.25);
    }

    .mainmenu-icons {
        width: 60px;
        height: 68px;
        margin-top: 6vh;
    }

    .mainmenu-tit {
        margin-top: 2vh;
    }

    .mainmenu-arrow {
        width: 30px;
        height: 52px;
        margin: 4vh 20px 0 auto;
    }
  }
}