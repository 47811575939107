//style-sheet
@import "./base/common";
@import "./base/mixin";
@import "./base/variables";

@import "./fonts/font.scss";

@import "./layout/header.scss";
@import "./layout/sidebar.scss";
@import "./layout/login.scss";

@import "./component/datatable.scss";
@import "./component/buttons.scss";
@import "./component/modals.scss";

//페이지 레이아웃
@import "./component/pages/main-user.scss";
@import "./component/pages/main.scss";
@import "./component/pages/page.scss";
@import "./component/pages/user-info.scss";
@import "./component/pages/survey.scss";
@import "./component//pages//withdrawal.scss";

//반응형 & 프린트 레이아웃
@import "./layout/mobile.scss";
@import "./layout/print.scss";
@import "./layout/print-foodblood.scss";

//Basic
* {
  margin: 0;
  padding: 0;
  letter-spacing: -0.2px;
}

a {
  text-decoration: none;
  color: #000;
}

a:visited {
  color: #000;
}

p {
  margin: 0;
}

li {
  list-style: none;
}

fieldset {
  border: none;
}

.emph {
  color: #e00036;
  font-weight: bold;
}

.bold {
  font-size: 15px;
  font-weight: 500;

  &.modal {
    font-size: 16px;
    font-weight: 600;
  }
}

#kt_wrapper {
  padding-top: 0 !important;
}

//탭메뉴 페이지(연속혈당기반 페이지, 만족도 조사 관리자 페이지)
.tabs-content-init {
  .tabs-content-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    .tabs-content-tit {
      font-size: 22px;
    }
  }

  .search-box {
    display: flex;
    padding: 20px 40px;
    margin-bottom: 20px;
    border: 1px solid #ececec;
    background: #fafafa;
    border-radius: 8px;
    align-items: left;
    flex-direction: row;
    justify-content: space-around;

    &.date-only {
      .search-tit {
        width: 50px !important;
      }
    }

    .search-factor {
      display: flex;
      align-items: center;

      & + .search-factor {
        margin-left: 10px;
      }

      .search-tit {
        width: 100px;
        margin-right: 15px;
        font-weight: 500;
        font-size: 14px;
        text-align: right;
      }

      form {
        display: flex;
        align-items: center;

        input {
          height: 34px;
          width: 161px;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 4px 5px;
        }

        .fromto {
          margin: 0 5px;
        }

        .btn-set.search {
          margin-left: 20px;
        }
      }
    }
  }

  .record-list {
    .list-btn-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5px 0;

      .list-btn {
        & + .list-btn {
          margin-top: 5px;
        }
      }
    }

    //CANPRO 정보
    #canpro_record_list {
      .dx-header-row {
        background: rgb(238, 238, 238);

        td {
          // text-align: center !important;
          vertical-align: middle !important;
        }
      }
    }
  }
}

.notice-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
}

.err-msg {
  color: #e00036;
}
