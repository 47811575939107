.page-wrap.user-list {
  //유저 정보 검색창 레이아웃
  .search-form {
    display: flex;
    padding: 20px 40px;
    margin-bottom: 20px;
    border: 1px solid #ececec;
    background: #fafafa;
    border-radius: 8px;
    flex-direction: column;

    .search-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &.date-full {
        justify-content: center;
        align-items: center;

        .search-factor {
          width: 50%;

          .pl-3 {
            flex-direction: row;
            display: flex;
            align-items: center;

            input {
              max-width: 140px;
            }

            .fromto {
              margin: 0 5px;
            }
          }
        }
      }

      .search-factor {
        display: flex;
        width: 25%;
        padding: 5px 0;
        align-items: center;

        .search-tit {
          min-width: 50px;
          text-align: right;
        }

        .pl-2,
        .pl-3 {
          width: 74%;

          input,
          select {
            width: 100%;
            height: 28px;
            border: 1px solid #ddd;
            border-radius: 2px;
          }

          &.tel {
            input,
            select {
              width: 30%;
            }

            span {
              display: inline-block;
              width: 5%;
              text-align: center;
            }
          }

          &.birth {
            input {
              width: 100%;
              border: none;
            }
          }
        }
      }
    }

    .search-btn-set {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  //그리드 수정, 삭제 버튼 레이아웃
  .list-btn-box {
    margin: 0;

    li {
      display: flex;
      width: 100%;
      justify-content: center;

      button {
        width: 40px;
        height: 24px;
        padding: 0;
        border-radius: 4px;

        & + button {
          margin-left: 5px;
        }
      }
    }
  }
}

//유저 정보 등록 Modal 레이아웃
.modal-wrap.user-list-modal {
  //모달 너비 설정
  .modal-dialog {
    max-width: 600px;
  }

  //모달 인풋 설정
  .row {
    display: flex;
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;

    & + .row {
      margin-top: 20px;
    }

    &.flex-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;

      .dx-datebox {
        width: 100% !important;
        height: 28px !important;

        input {
          border: none;
        }
      }

      input,
      select {
        width: 100% !important;
        height: 28px;
        padding: 5px;
      }
    }

    .form-password {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;

      .input-form {
        input {
          width: calc(100% - 96px);
        }
      }
    }

    .input-form {
      display: flex;
      width: 100%;
      align-items: center;

      label {
        min-width: 96px;
        padding-right: 10px;
        margin-bottom: 0 !important;
        font-size: 1.4rem;

        span {
          color: rgb(223, 0, 85);
          font-weight: 500;
        }
      }

      input,
      select {
        width: calc(50% - 106px);
        height: 28px;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 0.4rem;
      }

      //전화번호 입력 인풋 설정
      .form-phone {
        display: grid;
        width: 50%;
        grid-template-columns: 3fr 1fr 4fr 1fr 4fr;

        input,
        select {
          width: 100% !important;
        }

        span {
          text-align: center;
          line-height: 28px;
        }
      }

      //주소 입력 인풋 설정
      .form-address {
        display: flex;
        width: 100%;
        flex-direction: column;

        .zip {
          display: grid;
          width: 60%;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 10px;
          grid-column-gap: 10px;
        }

        input,
        select {
          width: 100% !important;
        }

        .address-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
        }
      }
    }

    //비밀번호 하단 참고문구
    .notice {
      margin-top: 5px;
      margin-left: 96px;
      color: rgb(119, 119, 119);
      font-size: 1.2rem;
    }
  }

  //중간 구분선
  hr {
    margin: 30px 0 !important;
  }
}
