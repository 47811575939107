
.page-content-wrap.predict {
    .section-init {
        overflow: visible;


        .result-bar {


//Circle Gauge 차트
            &.circle {
                float: left;
                width: 90%;
                height: auto;

                .gauge { 
                    margin: 0 auto;
                }

                .result-score-img {
                    padding: 30px 20px 0;
                    border-radius: 0.42rem;

                    img {
                        width: 100%;
                    }
                }
            }


//Linear Gauge 차트
            &.linear {
                overflow: visible !important;

                .bar-list {
                    overflow: visible;
                    display: flex;
                    margin: 20px auto 0;
                    padding: 0 20px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;

                    .bar-items {
                        overflow: visible;
                        display: flex;
                        width: 95%;
                        height: 40px;
                        align-items: center;

                        &+ .bar-items {
                            margin-top: 30px;
                        }

                        .item-tit {
                            width: 40%;
                            min-width: 120px;
                            padding-right: 10px;
                            padding-top: 6px;
                            font-size: 1.4rem;
                            font-weight: 500;
                        }

                        .lineargauge {
                            height: 40px;
                            width: 60%;
                            min-width: calc(100% - 100px);
                            // padding: 0 10px;
                            // background: #fff;
                            // box-shadow: 0px 2px 10px 0px rgba(0,0,0,.1);
                            // border-radius: 0.42rem;

                            svg {
                                overflow: visible !important;

                                .dxg-value-indicator {
                                        circle {
                                            -webkit-filter: drop-shadow(0px 0px 10px rgba(50 50 50/0.3));
                                            filter: drop-shadow(0px 0px 10px rgba(50 50 50/0.3));
                                        }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}