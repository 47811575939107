
//결과 페이지 공통
.page-wrap, .print-page-wrap {


//응답 입력값 다시보기 - 임상정보기반, 혈당모델링기반
  .answer-table {
    margin-bottom: 20px;
    background: #fff;
    border-radius: 0.42rem;
    border: 1px solid #039be5;

    .answer-tit {
      display: flex;
      padding: 10px 20px;
      color: #fff;
      font-weight: 500;
      border-radius: 0.2rem 0.2rem 0 0;
      align-items: center;
      background: #039be5;

      svg {
        g {
          path {
            fill: #fff;
          }
        }
      }
    }

    .answer-row {
      display: flex;
      padding: 10px 20px;
      flex-direction: column;
      margin-bottom: 0;

      li {
        display: flex;
        width: 100%;
        flex-direction: column;

        &+ li {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #c9c9c9;
        }

        .row-header {
          display: flex;
          width: 140px;
          margin-top: 10px;
          padding-left: 10px;
          color: #039be5;
          font-weight: 500;
          justify-content: flex-start;
          align-items: center;
        }

        .row-rows {
          display: flex;
          width: calc(100%);
          padding: 5px 0;
          flex-wrap: wrap;
          flex-wrap: wrap;
          flex-direction: row;

          div {
            display: flex;
            height: 68px;
            padding: 0 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
              min-width: 130px;
              min-height: 24px;
              color: rgb(59, 59, 59);
              font-size: 1.8rem;
              font-weight: 500;

              &.answer-subject {
                color: #7c7c7c;
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
  }


//결과페이지 공통 컨텐츠 레이아웃
  .page-content-wrap.result-page {
    background: none;
    box-shadow: none;
      
    .result {
      padding: 40px;
      width: 100%;
      background: #fff;
      border-radius: 0.42rem;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

      &+ .result {
        margin-top: 20px;
      }
    }


//결과 컨텐츠 타이틀
      .section-tit {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        color: #676767;
        line-height: 32px;
  
        .svg-icon-lg {
          svg {
            height: 28px;
            width: 28px;
            margin-bottom: 4px;
          }
        }
      }


//결과 컨텐츠 내용
      .section-init {
        display: flex;
        width: 100%;

        &+ .section-init {
          margin-top: 80px;
        }

        &.flex-row {
          width: 100%;

          &> div {
            overflow: hidden;
            width: 48%;
            height: auto;
            margin: 0 auto;

            &.result-bar {
              margin-right: 4%;
            }
          }
        }


//그래프 결과
        .result-bar {
          overflow: hidden;
          width: 100%;
          margin: 0 auto;

          .bar-tit {
            display: flex;
            width: 100%;
            height: 40px;
            margin-left: 20px;
            color: #676767;
            font-size: 1.8rem;
            font-weight: 500;
            align-items: center;
          }
        }


//세부내용 Wrap
      .section-explain {
        display: flex;
        width: 100%;

        &+ .section-explain {
          margin-top: 50px;
        }

        .result-exp {
          overflow: hidden;
          height: auto;
          width: 100%;
          font-size: 1.4rem;
          vertical-align: middle;

          &+ div {
            margin-top: 30px;
          }
  
          .result-tit {
            display: flex;
            width: 100%;
            height: 40px;
            margin-bottom: 20px;
            padding-left: 5px;
            color: #676767;
            font-size: 1.6rem;
            font-weight: 500;
            align-items: center;
          }

          p {
            width: 90%;
            margin: 0 auto;
            color: #676767;
            font-size: 1.6rem;
            line-height: 2.6rem;
            letter-spacing: -.5px;

            + p {
              margin-top: 10px;
            }

            span {
              font-weight: 600;
              color: #039be5;
            }
          }

          .exp-elem {
            padding: 20px;
            color: #676767;
            line-height: 2.6rem;
            font-size: 1.5rem;
            background: #f9faff;
            border-radius: 0.42rem;

            span {
              font-weight: 600;
              color: #000;

              &.highlight {
                color: #039be5;
              }
            }

            &+ .exp-elem {
              margin-top: 10px;
            }
          }
        }

        .result-txt {
          padding: 20px;
          color: #3f4254;
          font-size: 1.4rem;
          line-height: 2.2rem;
          background: #f9faff;
          border-radius: 0.42rem;

          &+ .result-txt {
            margin-top: 10px;
          }

          .exp-tit {
            display: flex;
            margin-bottom: 10px;
            font-size: 1.8rem;
            font-weight: 500;
            align-items: center;
          }

          p {
            color: #676767;
            font-size: 1.5rem;
            line-height: 2.6rem;
            letter-spacing: -.5px;

            span {
              font-weight: 600;
              color: #039be5;
            }
          }
        }


//컨텐츠 하단 참고내용
        .inner-foot {
          width: 100%;
          padding: 20px;
          margin-top: 30px;
          background: #f3f3f3;
          border-radius: 0.42rem;

          span {
            display: inline-block;
            margin-bottom: 8px;
            font-size: 1.6rem;
            font-weight: 500;
          }

          p {
            margin-bottom: 4px;
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }
      }
    }
  }


//설문조사 버튼 라인
  .survey-btn-wrap {
    display: flex;
    width: 100%;
    height: 85px;
    align-items: center;
    justify-content: center;
  
    a {
      display: inline-block;
      width: 100%;
      color: #fff;
    }
  }
}