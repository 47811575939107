#kt_aside {
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  .brand {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-bottom: 1px dotted #e7e7e7;

    button {
      width: 100%;

      .brand-tit {
        color: #414141;
        line-height: 2.8rem;
      }

      .svg-icon {
        width: 28px;
        height: 28px;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .aside-menu-wrapper {
    background: #fff;

    .aside-menu {
      background: none;
      margin: 0;

      .menu-nav {
        display: flex;
        align-items: flex-start;
        width: 90%;
        margin: 0 auto;

        .menu-item-submenu {
          .menu-link {
            min-height: 1.6rem;
            padding: 0 10px;

            .menu-text {
              display: flex;
              font-size: 1.4rem;
              color: #414141;
              align-items: flex-end;
            }
          }

          &:hover {
            .menu-link {
              background: #fff !important;
              cursor: default;
            }
          }
        }
      }

      .menu-content {
        height: calc(100vh - 127px);

        &.userlist {
          overflow: hidden;
          margin-top: 10px;

          .search_list {
            .dx-list-search {
              margin-bottom: 10px;

              .dx-icon-search {
                margin-left: 5px;
              }

              .dx-texteditor-input {
                padding-left: 40px;
              }

              .dx-placeholder {
                &::before {
                  padding-left: 40px;
                }
              }
            }

            .dx-scrollable-container {
              &::-webkit-scrollbar {
                width: 10px;
              }

              &::-webkit-scrollbar-thumb {
                background: #cfcfcf;
                border-radius: 5px;
                background-clip: padding-box;
                border: 2px solid transparent;
              }

              &::-webkit-scrollbar-track {
                background: #f9f9f9;
              }
            }

            .dx-list-item {
              border-bottom: 1px solid #e7e7e7;

              &.dx-state-focused {
                background: #2090ff;
                .user-set {
                  color: #fff;
                  font-weight: 600;

                  .user-num {
                    color: #2090ff;
                    background: #fff;
                    font-weight: 600;
                  }
                }

                &.dx-list-item-selected {
                  background: #2090ff;
                  .user-set {
                    color: #fff;
                    font-weight: 600;

                    .user-num {
                      color: #2090ff;
                      background: #fff;
                      font-weight: 600;
                    }
                  }
                }

                &.dx-state-active {
                  background: #187fe6;
                  .user-set {
                    color: #fff;
                    font-weight: 600;

                    .user-num {
                      color: #2090ff;
                      background: #fff;
                      font-weight: 600;
                    }
                  }
                }
              }

              &.dx-list-item-selected {
                background: #2090ff;
                .user-set {
                  color: #fff;
                  font-weight: 600;

                  .user-num {
                    color: #2090ff;
                    background: #fff;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }

        .dx-texteditor-input-container {
          height: 35px;
        }

        .user-set {
          width: 100%;
          float: left;
          padding: 14px 10px;
          color: #434343;

          .user-num {
            float: left;
            width: 20%;
            padding: 2px 4px;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
            background: #5f5f5f;
            border-radius: 4px;
          }

          .user-info {
            width: 80%;
            padding-left: 5%;
            float: right;
            font-size: 14px;

            .user-name {
              margin-bottom: 5px;
              font-weight: 600;

              .user-id {
                font-size: 1.2rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

//사이드 바 최소화
.aside-minimize {
  .aside {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    .aside-menu {
      .menu-nav {
        .menu-item-submenu {
          display: none;
        }

        .menu-link {
          padding: 0 22px !important;
        }

        .btn {
          display: none;
        }

        .menu-content {
          visibility: hidden;
        }
      }
    }
  }
}
