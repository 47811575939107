//인풋페이지 레이아웃
@import "./input/input-predict.scss";
@import "./input/input-pattern.scss";
@import "./input/input-ogtt.scss";
@import "./input/input-foodblood.scss";

//결과페이지 공통레이아웃
@import "./result/result.scss";

//결과페이지 레이아웃
@import "./result/result-predict.scss";
@import "./result/result-pattern.scss";
@import "./result/result-ogtt.scss";
@import "./result/result-foodblood.scss";

//페이지 외부요소 공통
.contents {
  padding: 0 !important;
}

#kt_content {
  .flex-column-fluid {
    height: auto;
    overflow: hidden;

    .content-container {
      width: 100%;
    }
  }

  &.content {
    padding: 0 !important;
  }
}

.page {
  display: flex;
  width: 100%;
  flex-direction: row;

  .no-padding {
    padding-left: 0 !important;
  }
}

//페이지 공통
.page-wrap,
.print-page-wrap {
  margin: 20px;

  .page-tit {
    display: flex;
    margin: 0 0 20px;
    color: #333333;
    align-items: baseline;
    justify-content: space-between;

    .tit-username {
      color: #0072db;
      font-size: 2rem;
      font-weight: 600;
      padding-left: 10px;
    }

    .data-indate {
      font-size: 1.3rem;
    }
  }

  //상단 안내라벨
  .head-info {
    padding: 14px 20px;
    margin-bottom: 30px;
    color: #fff;
    font-size: 14px;
    background: #2090ff;
    border-radius: 0.42rem;

    b {
      font-weight: 700;
    }

    a {
      color: #fff;
      border-bottom: 1px solid #fff;

      &:hover {
        color: rgb(255, 196, 0);
        border-bottom: 1px solid rgb(255, 196, 0);
      }
    }
  }

  //페이지 컨텐츠
  .page-content-wrap {
    background: #fff;
    border-radius: 0.42rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

    .table-container {
      padding: 40px;

      .section-tit {
        display: flex;
        width: 100%;
        height: 30px;
        margin-bottom: 30px;
        color: #4d4d4d;
        vertical-align: middle;
        border-bottom: 1px solid #a1a1a1;
      }

      //인풋 공통 - number input
      .number-input {
        input {
          height: 46px;
          font-size: 1.6rem;
        }
      }

      //인풋 공통 - radio
      .radio-select {
        .selection {
          display: block;
          float: left;
          width: 50%;

          input {
            float: left;
            height: 45px;
            width: 20px;
          }

          label {
            float: left;
            height: 45px;
            margin-left: 8px;
            font-size: 1.6rem;
            line-height: 45px;
          }
        }
      }
    }
  }
}
