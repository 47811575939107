//만족도 조사 페이지
.survey {
    .table-container {
      
      .section-tit {
        display: flex;
        height: auto;
        padding: 0 10px 6px;
        margin-bottom: 30px;
        font-size: 1.8rem;
        font-weight: 500;
        align-items: center;
      }
  
      .section-answer {
        display: flex;
        padding: 0 20px 60px;
        flex-direction: column;
  
        .selection {
          display: flex;
          align-items: center;
  
          &+ .selection {
            margin-top: 16px;
          }
  
          .radio-select {
            width: 18px;
            height: 18px;
          }
  
          .checkbox {
            width: 16px;
            height: 16px;
          }
  
          label {
            margin: 0 0 0 10px;
            font-size: 1.6rem;
          }
  
          .textbox {
            width: 30%;
            height: 35px;
            margin-left: 10px;
            padding: 5px 10px;
            background-color: #f4f6ff;
            border-radius: 4px;
            font-size: 1.6rem;
            border: none;
  
            &:disabled {
              background: rgb(240, 240, 240);
              border: 1px solid rgb(224, 224, 224);
            }
          }
        }
  
        &.text {
          padding: 0 0 60px;
  
          .selection {
            width: 100%; 
             
            .textbox {
              width: 100%;
              height: 40px;
              padding: 5px 10px;
              margin-left: 0;
              background-color: #f4f6ff;
              border-radius: 4px;
              border: none;
  
              &::placeholder {
                visibility: visible;
              }
  
              &:disabled {
                background: rgb(240, 240, 240);
                border: 1px solid rgb(224, 224, 224);
  
                &::placeholder {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
  
      .survey-submit-wrap {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
  
  .survey-admin {
    .search-form {
      display: flex;
      padding: 20px 40px;
      margin-bottom: 20px;
      border: 1px solid #ececec;
      background: #FAFAFA;
      border-radius: 8px;
      align-items: left;
      flex-direction: column;
  
      .search-box {
        border: none;
        background: none;
        padding: 0;
  
        input, select {
          height: 34px;
          width: 161px;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 4px 5px;
        }
      }
  
      .search-btn-set {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }