//회원탈퇴 신청 페이지
.withdrawal {
  display: -webkit-box;
  display: flex;
  width: 100vw;
  padding: 16px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  flex-direction: column;
  flex-grow: 1;

  .withdrawal-section {
    display: -webkit-box;
    display: flex;
    max-width: 600px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    flex-direction: column;

    .withdrawal-tit {
      display: -webkit-box;
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      font-size: 2.2rem;
      font-weight: 500;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      flex-direction: column;

      svg {
        width: 90%;
        margin-bottom: 10px;
      }
    }

    .withdrawal-box {
      display: -webkit-box;
      display: flex;
      width: 100%;
      padding: 30px;
      background: white;
      box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      flex-direction: column;

      & + .withdrawal-box {
        margin-top: 10px;
      }

      .tit-box {
        width: 100%;
        margin-bottom: 5px;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: -0.1rem;

        span {
          color: #df6464;
        }

        &.main {
          margin-bottom: 20px;
        }
      }

      .content-box {
        padding: 0 10px;
        font-size: 1.5rem;
        letter-spacing: -0.07rem;

        p {
          margin-bottom: 0;

          & + p {
            margin-top: 5px;
          }

          .underline {
            color: #4344e1;
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }

      .content-sub-box {
        width: 100%;
        margin-bottom: 10px;
        color: #797979;
        font-size: 1.2rem;
        letter-spacing: -0.07rem;
      }

      .table {
        margin-top: 20px;
        margin-bottom: 0;
        border: none;

        thead {
          tr {
            th {
              padding: 6px;
              color: white;
              font-size: 1.2rem;
              text-align: center;
              background: #4755eb;
              border-bottom: none;

              &:first-child {
                border-radius: 4px 0 0 4px;
              }

              &:last-child {
                border-radius: 0 4px 4px 0;
              }
            }
          }
        }

        tbody {
          td {
            padding: 10px;
            color: #2b2f45;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            vertical-align: middle;
            background: #f9f9f9;
            border-top: none;

            &:first-child {
              width: 80px;
              color: #797979;
              font-size: 1.2rem;
              font-weight: 400;
              border-radius: 4px 0 0 4px;
            }

            &:last-child {
              border-radius: 0 4px 4px 0;
            }
          }
        }
      }

      .input-form {
        width: 100%;

        .input-box {
          display: -webkit-box;
          display: flex;
          width: 100%;
          color: #797979;
          -webkit-box-orient: vertical;
          flex-direction: column;

          input {
            height: 40px;
            padding: 0 10px;
            border: 1px solid #dfdfdf;
            border-radius: 5px;
          }

          & + .input-box {
            margin-top: 10px;
          }
        }

        button {
          width: 100%;
          height: 48px;
          margin-top: 30px;
          color: white;
          font-size: 1.6rem;
          font-weight: 500;
          background: #4755eb;
          border: none;
          border-radius: 5px;

          &:active {
            background: #2b39d0;
          }
        }
      }
    }
  }

  .withdrawal-footer {
    display: -webkit-box;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 16px;
    color: #2b2f45;
    font-size: 1.6rem;
    letter-spacing: -0.07rem;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }
}
