
.page-wrap.pattern {

//Full-size 인풋 페이지 타이틀
    .section-tit {
        margin-bottom: 4px !important;
        border-bottom: none !important;
    }


//Select 테이블 레이아웃
    .table-wrap {
        overflow: auto;
        width: 100%;
        height: calc(100vh - 390px);
        border-radius: 0.42rem;
        border: 1px solid #a1a1a1;


//테이블 내부 스크롤바 
        &::-webkit-scrollbar {
            border-radius: 10px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #c4c4c4;
            border-radius: 10px;
            background-clip: padding-box;
            border: 3px solid transparent;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: none;
        }


//테이블 레이아웃
        table {
            width: 100%;
            margin: 0 auto;
            border-collapse: collapse;

            thead {
                th {
                    position: sticky;
                    padding: 10px 0;
                    font-size: 1.6rem;
                    font-weight: 500;
                    text-align: center;
                    background-color: #e8ecee;
                    border: 1px solid #a1a1a1;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 100%;
                        bottom: -1px;
                        border: 1px solid #a1a1a1;
                    }

                    &.head1 {
                        border-top: none;
                        border-bottom: none;
                        border-left: none;
                        top: 0;
                    }

                    &.head2 {
                        top: 44px;
                        border-top: none;
                        border-bottom: none;
                    }

                    &.head3 {
                        top: 88px;
                        border-top: none;
                    }
                }
            }

            tbody {
                border-top: none;

                tr {
                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                
                    &:nth-child(2n) {
                        background: #f9f9f9;
                    }
                }

                td {
                    padding: 20px 13px;
                    font-size: 1.6rem;
                    border: 1px solid #a1a1a1;

                    &:first-of-type {
                        border-left: none;
                        text-align: left;
                    }

                    input {
                        display: block;
                        -webkit-appearance: none;
                        width: 24px;
                        height: 24px;
                        margin: 0 auto;
                        padding: 0;
                        border-radius: 50%;
                        outline: none;
                        border: 2px solid grey;
                        background: #fff;

                        &:checked {
                            border-color: #039be5;

                            &::before {
                                background: #039be5;
                                border: 3px solid #039be5;
                            }
                        }

                        &::before {
                            content: "";
                            display: block;
                            width: 70%;
                            height: 70%;
                            margin: 15% auto 0;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}