
.page-content-wrap.foodblood {
    .result {
        .section-init {

            &.part {
                padding: 20px;
                background: #f9faff;
                border-radius: 0.42rem;
            }

            .result-bar {


//평균 칼로리 섭취량 그래프
                &.chart-bullet {
                    .exp-chart {
                        display: flex;
                        max-height: 140px;

                        .pf-c-chart {
                            overflow: hidden; 
                            border-radius: 4px;
        
                            g {
                                &:nth-child(2) {
                                    path {
                                        fill: #4BA9FF !important;
                                    }
                                }
        
                                &:nth-child(3) {
                                    path {
                                        stroke: #f16744 !important;
                                    }
                                }
                            }
                        }
                    }
                }


                &.pie {
                    .print {
                        display: none;
                        width: 450px !important;
                    }
                }
            }  

            .section-explain {
                .cal-status {
                    display: flex;
                    color: #616161;
                    margin-top: auto;
                    margin-left: 1rem;
                    font-size: 1.4rem;
                    letter-spacing: -0.8px;
                    flex-direction: column;
                    justify-content: space-between;

                    .cal-suggest {
                        display: flex;
                        width: 100%;
                        height: 55px;
                        margin-bottom: 5px;
                        padding: 5px 0;
                        justify-content: space-evenly;
                        align-items: center;
                        color: #4a4a4a;
                        font-size: 1.6rem;
                        border: 1px solid #e7e7e7;
                        border-radius: 4px;
                        background: #fff;
                    
                        .divide {
                            border-left: 1px solid rgba(#e7e7e7, 1);
                        }
                    }
                
                    .cal-info {
                        display: flex;
                        width: 100%;
                        height: 55px;
                        margin-top: 5px;
                        padding: 5px 0;
                        justify-content: space-evenly;
                        align-items: center;
                        color: #fff;
                        font-size: 1.6rem;
                        border-radius: 4px;
                    
                        &.less {
                            background-color: #2295FF;
                        }
                    
                        &.more {
                            background-color: #f16744;
                        }

                        .divide {
                            border-left: 1px solid rgba(#fff, .2);
                            padding-left: 5px;
                        }
                    }
                
                    .divide {
                        width: 50%;
                        font-weight: 500;
                        text-align: center;
                        letter-spacing: -0.8px;
                    
                        span {
                            margin-left: 4px;
                            margin-right: 2px;
                            font-size: 1.8rem;
                        }
                    }
                }


//결과 내용 레이아웃
                .result-exp {
                    .exp-outer {
                        &+ .exp-outer {
                            margin-top: 20px;
                        }

                        .exp-name {
                            font-size: 1.6rem;
                            font-weight: 500;
                        }

                        .exp-elem {
                            span {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }


//식후 혈당 변화에 따른 식단 평가
        .food-est-wrap {
            overflow: hidden;
            display: flex;
            height: auto;
            flex-direction: column;
    
            .food-est-init {
                overflow: hidden;
                border-radius: 0.42rem;
                margin-bottom: 20px;
                cursor: pointer;


//해당되지 않는 평가란 숨김처리
                &.invisible {
                    display: none;
                }
    

//버튼 클릭 시 펼침
                &.active {
                    max-height: fit-content;
                    flex-direction: column;
                    background: #fff;

                &.good {
                    .diet-list {
                        border: 1px solid #00CAB2;
                        border-radius: 0 0 0.42rem 0.42rem;
                    }
                }
    
                &.fine {
                    .diet-list {
                        border: 1px solid #33B900;
                        border-radius: 0 0 0.42rem 0.42rem;
                    }
                }
    
                &.worried {
                    .diet-list {
                        border: 1px solid #febb01;
                        border-radius: 0 0 0.42rem 0.42rem;
                    }
                }
    
                &.caution {
                    .diet-list {
                        border: 1px solid #FF8A01;
                        border-radius: 0 0 0.42rem 0.42rem;
                    }
                }
    
                &.bad { 
                    .diet-list {
                        border: 1px solid #EC0C00;
                        border-radius: 0 0 0.42rem 0.42rem;
                    }
                }
    

//상단 레벨 설명(버튼부)
                    .food-est-level {
                        .food-est-arrow {
                            transform: rotate(180deg);
                            transition: all 0.3s ease;
                        }
    
                        .level-exp {
                            display: block;
                            margin-top: 10px;
                            padding: 0 5px; 
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }
                    }


//해당 레벨 식단 리스트
                    .diet-list {
                        width: 100%;
                        display: flex;
                        padding: 15px 30px;
                        flex-direction: column;
                        align-items: center;
                        border: none;

                        .exp-wrap {
                            width: calc(45%);
                            height: auto;
                            min-width: 600px;
                            margin: 15px 30px;
                            padding: 30px;
                            border: none;
                            border-radius: 0;
                            background: #fafafa;
                            border: 1px solid #ececec;
                            border-radius: 4px;
                        }
                    }
                }


//버튼 레이아웃(레벨별)
                &.good {
                    .food-est-level {
                        background: #00CAB2;
                        border: 1px solid #00CAB2;

                        .level-icon {
                            background: url('../../../media/icons/good.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }

                &.fine {
                    .food-est-level {
                        background: #33B900;
                        border: 1px solid #33B900;

                        .level-icon {
                            background: url('../../../media/icons/fine.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }

                &.worried {
                    .food-est-level {
                        background: #febb01;
                        border: 1px solid #febb01;

                        .level-icon {
                            background: url('../../../media/icons/worried.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }

                &.caution {
                    .food-est-level {
                        background: #FF8A01;
                        border: 1px solid #FF8A01;

                        .level-icon {
                            background: url('../../../media/icons/caution.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }

                &.bad {
                    .food-est-level {
                        background: #EC0C00;
                        border: 1px solid #EC0C00;

                        .level-icon {
                            background: url('../../../media/icons/bad.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }



                .food-est-level {
                    display: flex;
                    padding: 10px 20px 10px;
                    color: #fff;
                    flex-direction: column;
    
                    .est-level {
                        display: flex;
                        font-size: 18px;
                        align-items: center;
                        font-weight: 600;
                        justify-content:space-between;
    
                        &> span {
                            display: flex;
                            align-items: center; 
    
                            .level-icon {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                margin-right: 5px;
                            }
                        }
    
                        .food-est-arrow {
                            transition: all 0.3s ease;
    
                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
    
                    .level-exp {
                        display: none;
                        margin-bottom: 5px;
                    }
                }


//식단 분석 테이블
                .diet-list {
                    padding: 0;

                    .exp-wrap {
                        overflow: hidden;
                        height: 0;


//식단 분석 테이블 - 식단 정보
                        .diet-info {
                            width: 100%;
                            display: flex;
                            flex-direction: column;


//식단 분석 테이블 - 식단 타이틀
                            .info-date {
                                .date-tit {
                                    display: flex;
                                    width: 100%;
                                    flex-direction: row;
                                    justify-content: space-between;

                                    .date-txt {
                                        color: #242424;
                                        font-size: 1.6rem;
                                        font-weight: 500;
                                        border-bottom: none;
                        
                                        svg {
                                            margin-bottom: 4px;
                        
                                            g {
                                                path {
                                                    fill: #242424;
                                                }
                                            }  
                                        }
                                    }
                        
                                    .diet-level {
                                        display: flex;
                                        align-items: center;
                        
                                        .level-icon {
                                            display: inline-block;
                                            width: 24px;
                                            height: 24px;
                                        }
                        
                                        .level-txt {
                                            padding: 1px 8px;
                                            margin-left: 4px;
                                            color: #fff;
                                            font-weight: 500;
                                            border-radius: 4px;
                                        }
                        
                                        &.good {
                                            .level-icon {
                                                background: url('../../../media/icons/good.png') no-repeat;
                                                background-size: contain;
                                            }
                        
                                            .level-txt {
                                                background: #00CAB2;
                                            }
                                        }
                        
                                        &.fine {
                                            .level-icon {
                                                background: url('../../../media/icons/fine.png') no-repeat;
                                                background-size: contain;
                                            }
                        
                                            .level-txt {
                                                background: #33B900;
                                            }
                                        }
                        
                                        &.worried {
                                            .level-icon {
                                                background: url('../../../media/icons/worried.png') no-repeat;
                                                background-size: contain;
                                            }
                        
                                            .level-txt {
                                                background: #febb01;
                                            }
                                        }
                        
                                        &.caution {
                                            .level-icon {
                                                background: url('../../../media/icons/caution.png') no-repeat;
                                                background-size: contain;
                                            }
                        
                                            .level-txt {
                                                background: #FF8A01;
                                            }
                                        }
                        
                                        &.bad {
                                            .level-icon {
                                                background: url('../../../media/icons/bad.png') no-repeat;
                                                background-size: contain;
                                            }
                        
                                            .level-txt {
                                                background: #EC0C00;
                                            }
                                        }
                                    }
                                }

                                .food-list {
                                    margin: 20px 0;
                                    padding: 10px 20px;
                                    background: #fff;
                                    font-size: 1.4rem;
                                    border: 1px solid #ececec;
                                    border-radius: 4px;
                                }
                            }
                        }


//식단 분석 테이블 - 식단 분석
                        .diet-init {
                            display: flex;
                            flex-direction: row;

                            .diet-img {
                                width: 50%;

                                img {
                                    width: 100%;
                                    height: 190px;
                                    margin-bottom: 10px;
                                    background: #f6f6f6;
                                    border-radius: 4px;
                                    object-fit: cover;
                                }
                            }

                            .info-score {
                                display: flex;
                                width: 45%;
                                margin-left: 5%;
                                align-items: center;
                                justify-content: center;
    
                                .nut-1 {
                                    width: 100%;
                                }
    
                                .line {
                                    display: inline-block;
                                    width: 1px;
                                    height: 80px;
                                    background: #ececec;
                                }
                        
                                .nut-item {
                                    display: flex;
                                    align-items: center;
                        
                                    &+ .nut-item {
                                        margin-top: 20px;
                                    }
                        
                                    .nut-i-tit {
                                        font-size: 1.4rem;
                                        color: #616161;
                                    }
                        
                                    .nut-i-cont {
                                        margin-left: auto;
                                        font-size: 1.4rem;
                                        color: #000;
                        
                                        &::before {
                                            content:"・・・ ";
                                            color: #616161;
                                        }
                                    }
                                }
                            }
                        }


//식후 혈당변화 그래프
                        .bloodlevel-change {
                            display: flex;
                            margin-top: 20px;
                            padding: 20px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            background: #fff;
                            border: 1px solid #ececec;
                            border-radius: 4px; 

                            .print {
                                overflow: hidden;
                                height: 0;
                            }

                            .nut-2 {
                                display: flex;
                                width: 90%;
                                margin-top: 20px;
                                flex-direction: column;
                            
                                .nut-item {
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
            
                                    &+ .nut-item {
                                        margin-top: 20px;
                                    }
            
                                    .nut-i-tit {
                                        color: #616161;
                                    }
            
                                    .nut-i-cont {
                                        margin-left: auto;
                                        font-size: 1.4rem;
                                        color: #000;
            
                                        &::before {
                                            content:"・・・ ";
                                            color: #616161;
                                        }
                                    }
            
                                    .nut-i-level {
                                        width: 34px;
                                        height: 18px;
                                        margin-left: 5px; 
                                        color: #fff;
                                        font-size: 1.2rem;
                                        text-align: center;
                                        border-radius: 4px;
            
                                        &.scale {
                                            width: unset;
                                            height: unset;
                                            padding: 2px 8px;
                                            font-size: 1.2rem; 
                                            font-weight: 500;
                                        }
    
                                        &.more {
                                            background: #0077E4;
                                        }
            
                                        &.less {
                                            background: #f16744;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }    
    }
}