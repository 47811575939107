@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 200;
  src: url("./SpoqaHanSansNeo-Thin.woff2") format("woff2"),
    url("./SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("./SpoqaHanSansNeo-Thin.ttf") format("ttf");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 300;
  src: url("./SpoqaHanSansNeo-Light.woff2") format("woff2"),
    url("./SpoqaHanSansNeo-Light.woff") format("woff"),
    url("./SpoqaHanSansNeo-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 400;
  src: url("./SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("./SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("./SpoqaHanSansNeo-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 500;
  src: url("./SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("./SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("./SpoqaHanSansNeo-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 600;
  src: url("./SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("./SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("./SpoqaHanSansNeo-Bold.ttf") format("ttf");
}

* {
  letter-spacing: -0.5px;
}

html {
  &.diabetes {
    font-size: 10px !important;
    font-family: Spoqa Han Sans !important;
  }
}

body {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Spoqa Han Sans !important;
}

//폰트 색상옵션
.font-color-white {
  color: #fff;
}
.font-color-red {
  color: red;
}

//폰트 두께 옵션
.font-w-bold {
  font-weight: bold;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

//폰트 사이즈 옵션
.font-size-12 {
  font-size: 1.2rem !important;
}

.font-size-14 {
  font-size: 1.4rem !important;
}

.font-size-15 {
  font-size: 1.5rem !important;
}

.font-size-16 {
  font-size: 1.6rem !important;
}

.font-size-18 {
  font-size: 1.8rem !important;
}

.font-size-24 {
  font-size: 2.4rem;
}

.font-size-28 {
  font-size: 2.8rem;
}

.font-size-80 {
  font-size: 8rem;
}

.t-a-left {
  text-align: left;
}

.letter-space-1 {
  letter-spacing: -1px;
}

//폰트 타이틀 레벨 옵션
.p1 {
  font-size: 2.2rem !important;
}

.p2 {
  font-size: 2rem !important;
}

.p3 {
  font-size: 1.8rem !important;
}

.p4 {
  font-size: 1.6rem !important;
}

.p-content {
  font-size: 1.4rem !important;
}
