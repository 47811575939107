.login-wrap {
    display: flex;
    height: 100vh;
    background: url("../media/images/loginpage.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: center;
  
    .login-pannel {
      max-width: 90vw;
      background: #fff;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.61);
  
      .login-form {
        overflow: hidden;
        max-width: 290px;
        height: auto;
        margin: 0 80px;
  
        &.register {
          max-width: 700px;
          width: calc(90vw - 120px);
          margin: 0 60px;
        }
  
        .login-tit {
          margin: 90px auto 50px;
          text-align: center;
  
          &.register {
            margin: 50px auto 0;
          }

          .login-logo {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
            background: url('../media/icons/diaicon.png') no-repeat;
            background-size: contain;
            background-position: center;
          }
  
          h3 {
            margin: 0 10px;
            color: #353535;
            font-size: 2.2rem !important;
            font-weight: 500;
          }
        }
  
        .form {
          margin-bottom: 100px;

          &.register {
            overflow-y: auto;
            height: 70vh;
            padding: 0 40px;
            margin-bottom: 50px;
  
            &::-webkit-scrollbar {
              width: 5px;
            }
  
            &::-webkit-scrollbar-thumb {
              background: #cfcfcf;
              border-radius: 5px;
            }
  
            &::-webkit-scrollbar-track {
              background: none;
            }
  
            .membership-init-tit {
              margin: 30px 0 20px;
              font-size: 1.4rem;
              font-weight: 500;
            }
          }
  
          .form-group {
            width: 100%;
            margin-bottom: 15px;
  
            input,
            select {
              border: 1px solid #cfcfcf;
              background: #fff;
              font-size: 1.2rem;
            }
  
            &.register {
              input,
              select {
                width: calc(100% - 15px);
                height: 40px;
                margin-left: 15px;
              }
  
              &#register_bmi {
                border: 1px solid #393939;
                background: #e7eff6;
              }
            }
  
            &.form-group-btn {
              margin-top: 40px;
            }
  
            &.form-group-last {
              justify-content: flex-end;
              margin-bottom: 90px;
            }
  
            &.grid-2 {
              display: grid;
              grid-template-columns: 2fr 12fr;
            }
  
            &.grid-3 {
              display: grid;
              grid-template-columns: 2fr 6fr 6fr;
            }
  
            &.grid-4 {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
  
            &.grid-6 {
              display: grid;
              grid-template-columns: 2fr 3fr 1fr 3fr 1fr 4fr;
  
              span {
                line-height: 40px;
                text-align: center;
              }
  
              .tel-num {
                width: 100%;
                margin-left: 0;
              }
            }
  
            .form-label {
              display: flex;
              margin-bottom: 0;
              font-size: 1.2rem;
              align-items: center;
              justify-content: flex-end;
            }

            .fv-plugins-message-container {
              .fv-help-block {
                font-size: 1rem; 
              }
            }
  
            .basic-info-group {
              display: grid;
              grid-template-columns: 2fr 5fr;
  
              &.bmi {
                grid-template-columns: 7fr;
  
                .bmi-exp {
                  display: flex;
                  padding-left: 15px;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
  