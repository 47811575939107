//Modal
.modal-overlay {
  box-sizing: border-box;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;

  &.active {
    display: block;
  }
}

.modal-wrap {
  box-sizing: border-box;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;

  &.active {
    display: block;
  }

  .print {
    width: 1040px;
    max-width: unset !important;
  }

  .modal-header {
    display: flex;
    align-items: center;
    border-bottom: none;
    padding: 30px 30px 0px;

    .modal-tit {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  .modal-body {
    padding: 30px;

    .food-number-wrap {
      margin-bottom: 20px;

      .food-number {
        padding-left: 10px;

        span {
          font-size: 1.2rem;
        }

        input {
          height: 30px;
          padding: 5px 10px;
          margin-left: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
      }
    }

    form {
      .food-input-wrap {
        width: 100%;
        margin-bottom: 30px;
        padding: 30px;
        background: #f9fbff;
        border: 1px solid #ececec;
        border-radius: 5px;

        .beforeTime,
        .afterTime {
          margin-bottom: 14px;

          label {
            margin-bottom: 0;
          }

          #beforeTime,
          #afterTime {
            width: 35%;
            height: 30px;
            padding: 1px 0 1px 5px;
          }
        }

        .img-input-table {
          .input-table-tit {
            margin-bottom: 10px;
            font-size: 1.2rem;
            font-weight: 500;
          }

          .img-input-wrap {
            display: flex;
            justify-content: space-between;

            .img-input {
              display: flex;
              flex-direction: column;
              align-items: center;

              img {
                width: 120px;
                height: 120px;
                border-radius: 6px;
              }

              .img-input-label {
                display: flex;
                width: 120px;
                height: 120px;
                margin-bottom: 0;
                color: #fff;
                background: #a3a3a3;
                // background: #0064DA;
                border-radius: 6px;
                cursor: pointer;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &:hover {
                  // background: #005eca;
                  background: #919191;
                }

                &:active {
                  // background: #0059be;
                  background: #808080;
                }

                .photo-in {
                  width: 28px;
                  height: 28px;
                  margin-bottom: 5px;
                  background: url("../media/images/Exclude.png") no-repeat;
                  background-position: center;
                  background-size: contain;
                }

                &.change {
                  width: 60px;
                  height: 30px;
                  margin-top: 10px;
                  background: #0072db;

                  &:hover {
                    background: #0067c7;
                  }

                  &:active {
                    background: #005eb6;
                  }
                }
              }

              input {
                display: none;
              }
            }
          }
        }
      }
    }

    .card {
      &.card-custom {
        box-shadow: none;
      }
    }

    .bloodactive-graph {
      display: flex;
      height: 290px;
      margin: 0 auto;
      background: #ddd;
      align-items: center;
      justify-content: center;
    }

    .diet-log-list {
      margin: 30px auto 20px;

      .diet-log-item {
        margin: 0 10px;

        & + .diet-log-item {
          margin-top: 30px;
          padding-top: 20px;
          border-top: 1px dashed #b0b0b0;
        }

        .diet-log-tit {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
        }

        .diet-log-content {
          display: flex;
          height: auto;
          min-height: 120px;
          flex-direction: row;
          align-items: center;

          img {
            width: 120px;
            height: 120px;
            border-radius: 4px;
          }

          .diet-log-food {
            overflow: auto;
            display: flex;
            min-width: 120px;
            height: 120px;
            margin: 0 10px;
            padding: 10px;
            font-size: 14px;
            background: #f9fbff;
            border: 1px solid #ececec;
            border-radius: 4px;
            flex-direction: column;

            .food-items {
              &::before {
                content: "∙";
                margin-right: 5px;
              }
            }

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #ddd;
              border-radius: 10px;
              border: 2px solid transparent;
            }
          }

          .diet-log-nut {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            ul {
              &.nut-1 {
                width: 46%;
                margin-right: 6%;
              }

              &.nut-2 {
                width: 36%;
                margin-left: calc(6% - 1px);
              }

              .nut-item {
                line-height: 23px;

                & + .nut-item {
                  margin-top: 15px;
                }

                .nut-i-tit {
                  color: #616161;
                }

                .nut-i-cont {
                  float: right;
                  font-size: 15px;
                  color: #000;

                  &::before {
                    content: "・・・ ";
                    color: #616161;
                  }
                }
              }
            }

            .line {
              display: inline-block;
              width: 1px;
              height: 80px;
              background: #ececec;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    border-top: none;
  }
}
